import React from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncSelect } from '@lon/shared/components';
import { useAuth, useDebounce } from "@lon/shared/hooks";
import { UserTypeEnum, useGetUsersLazyQuery, UserPurposeEnum } from "@lon/shared/requests";
import { AsyncPaginateComponentProps } from './duck';

const AsyncSelectComponent: React.FC<AsyncPaginateComponentProps> = ({
  name,
  testId,
  getTeachers,
  index,
  selectedIndex,
  tooltipEntity,
  ...rest
}) => {
  const [fetch, { loading }] = useGetUsersLazyQuery();
  const { t } = useTranslation();
  const [search, setSearch] = React.useState('');
  const [{ user }] = useAuth();

  const loadOptions = useDebounce((value: any, callback: any) => {
    fetch({
      variables: {
        page: 1,
        fullName: value,
        type: UserTypeEnum.Staff,
        itemsPerPage: 300,
        active: true,
        purpose: user?.purpose || UserPurposeEnum.Regular
      },
    }).then((res: any) => {
      callback(getTeachers(res?.data));
    });
  });

  return (
    <AsyncSelect
      {...rest}
      name={`teams.${index}.members`}
      label={t('userSettings.teams.teamMembers', {
        entity: `${(selectedIndex || 0) + 1}`,
      })}
      tooltipEntity={tooltipEntity}
      isRequired
      isClearable={false}
      isMulti
      isLoading={loading}
      inputValue={search}
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      onInputChange={setSearch}
    />
  );
};

export default AsyncSelectComponent;
