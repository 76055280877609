import { get } from 'lodash-es';

export const customSort = (arr?: any[], path?: string) =>
  (arr || []).sort((a, b) => {
    const valueA = (path ? get(a, path, '') : a).trim();
    const valueB = (path ? get(b, path, '') : b).trim();

    // Blank values go at the end
    if (!valueA || !valueB) {
      return !valueA ? 1 : -1;
    }

    // Numbers come after letters
    const isANumber = /^\d/.test(valueA);
    const isBNumber = /^\d/.test(valueB);

    if (isANumber && isBNumber) {
      return valueA.localeCompare(valueB);
    } else if (isANumber) {
      return 1;
    } else if (isBNumber) {
      return -1;
    }

    // If both words start with the same letter, prioritize uppercase
    if (valueA[0].toLowerCase() === valueB[0].toLowerCase()) {
      if (valueA[0] !== valueB[0]) {
        return valueA[0] < valueB[0] ? -1 : 1;
      }
    }

    return valueA.localeCompare(valueB, 'en', { caseFirst: 'upper' });
  });
