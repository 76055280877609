export const getSrcBasedOnLanguage = (src?: string) => {
  const language = document.documentElement.lang;

  if (language === 'es') {
    return src?.includes('/EN_') ? src.replace('/EN_', '/ES_') : src;
  } else if (language === 'en') {
    return src?.includes('/ES_') ? src.replace('/ES_', '/EN_') : src;
  } else {
    return src;
  }
};
