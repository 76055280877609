import {
  Accordion,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { get, mergeWith, uniq } from 'lodash-es';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { WorkingLocation } from '@lon/shared/contexts';
import {
  useGetIsLeaderSuitFlag,
  useGetSchoolIdsForPlanner as useGetSchoolIds,
  useQueryParams,
} from '@lon/shared/hooks';
import {
  useGetCurriculumAreasQuery,
  useGetGradesQuery,
  useGetTopicsQuery,
} from '@lon/shared/requests';
import { sortGrades } from '@lon/shared/utils';
import {
  TeacherPresets,
  collapsibleSidebarPaddingRight,
} from '@lon/suit/components';
import { RootState } from '@lon/suit/configs';
import { tabsConstants, utils } from './duck';
import { AccordionFilterItem, RecentlyViewed, ResetButton } from './components';

const FilterTabs = () => {
  const { t } = useTranslation();
  const isLeader = useGetIsLeaderSuitFlag();
  const [params, setParams] = useQueryParams();
  const { currentSchoolId, currentAppSchoolIds } = useContext(WorkingLocation);
  const accessibleSchoolIds = useGetSchoolIds();
  const { data: grades, loading: isGradesLoading } = useGetGradesQuery({
    variables: isLeader
      ? { schoolIds: currentAppSchoolIds }
      : { schoolId: currentSchoolId as string },
  });
  const { data: topics, loading: isTopicsLoading } = useGetTopicsQuery({
    variables: { schoolIds: accessibleSchoolIds },
  });
  const { data: curriculumAreas, loading: isCurriculumAreasLoading } =
    useGetCurriculumAreasQuery({
      variables: isLeader
        ? { schoolIds: currentAppSchoolIds }
        : { schoolId: currentSchoolId as string },
    });

  const savedFilters = useSelector(({ filters }: RootState) =>
    get(filters, `scopes.filters`, '')
  );

  useEffect(() => {
    const customizer = (first: string[], second: string[]) =>
      uniq([first, second].flat().filter(Boolean));

    const initialFilters = mergeWith(
      {},
      params.filters,
      savedFilters,
      tabsConstants.data,
      customizer
    );

    setParams(
      {
        ...params,
        filters: initialFilters,
      },
      { replace: true }
    );
  }, []);

  const isFiltersLoading =
    isGradesLoading || isTopicsLoading || isCurriculumAreasLoading;
  const filters = useMemo(
    () => ({
      grades: sortGrades(grades?.grades?.grades),
      topics: utils.sortAlphabetical(topics?.topics?.topics),
      curriculumAreas: utils.sortAlphabetical(
        curriculumAreas?.curriculumAreas?.curriculumAreas
      ),
    }),
    [grades, topics, curriculumAreas]
  );

  useEffect(() => {
    if (!isFiltersLoading) {
      // @ts-ignore
      window?.runGoogleTranslate(document.documentElement.lang || 'en');
    }
  }, [isFiltersLoading]);

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <Tabs
      variant="simple"
      index={tabIndex}
      onChange={handleTabsChange}
      pl="6"
      pr={collapsibleSidebarPaddingRight}
    >
      <TabList gap={8}>
        {tabsConstants.TABS.map((tab) => (
          <Tab key={tab.name}>{t(tab.name)}</Tab>
        ))}
      </TabList>
      <TabPanels>
        <TabPanel display="flex" flexDirection="column" gap="6" px="0">
          <TeacherPresets data={tabsConstants.data} />
          <Accordion
            display="flex"
            flexDir="column"
            gap={6}
            rowGap={4}
            allowMultiple
            borderRadius="4px"
            as="ul"
          >
            {tabsConstants.MENU_ITEMS.filter(({ hide }) => !hide).map(
              ({ key, label, name }) => (
                <AccordionFilterItem
                  name={name}
                  label={t(label)}
                  data={filters?.[key] || []}
                  loading={isFiltersLoading}
                  key={key}
                />
              )
            )}
          </Accordion>
          <ResetButton />
        </TabPanel>
        <TabPanel px="0">
          <RecentlyViewed />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default FilterTabs;
