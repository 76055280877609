import { IndeterminateCheckbox } from '../components/table-content/components';
import {
  ColumnDef,
  PaginationState,
  Row,
  SortingState,
  Table,
} from '@tanstack/react-table';
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  MultiPageSelectManager,
  UsePrepareData,
  UseServerSidePaginationAndSorting,
} from './types';

export const usePrepareData = <Data extends object>({
  data,
  columns,
  renderRowActions,
  enableRowSelection,
  isRowSelectionDisabled,
  hasServerSidePagination,
  multiPageSelectManager,
}: UsePrepareData<Data>) => {
  const totalCount = multiPageSelectManager?.totalCount || data.length;
  const onMultiPageSelectChange = (
    table: Table<Data>,
    multiPageSelectManager: MultiPageSelectManager
  ) => {
    const { isSelectedAll, setIsSelectedAll, excludedRows, setExcludedRows } =
      multiPageSelectManager;
    if (isSelectedAll) {
      table.resetRowSelection();
      if (excludedRows?.size) {
        setExcludedRows(new Set());
      }
    } else {
      table.toggleAllRowsSelected(true);
    }

    setIsSelectedAll((prev) => !prev);
  };

  const onRowSelectChange = ({
    event,
    table,
    row,
    multiPageSelectManager,
  }: {
    event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLDivElement>;
    table: Table<Data>;
    row: Row<Data>;
    multiPageSelectManager: MultiPageSelectManager;
  }) => {
    const { isSelectedAll, setIsSelectedAll, excludedRows, setExcludedRows } =
      multiPageSelectManager;
    const isRowSelected = row.getIsSelected();
    const isLastRowToBeSelected =
      !isRowSelected &&
      Object.keys(table.getState().rowSelection).length === totalCount - 1;

    row.getToggleSelectedHandler()(event);

    if (isSelectedAll) {
      const newExcludedRows = new Set(excludedRows);

      if (excludedRows.has(row.id)) {
        newExcludedRows.delete(row.id);
      } else {
        const isLastRowToBeDeselected = excludedRows.size === totalCount - 1;
        if (isLastRowToBeDeselected) {
          setIsSelectedAll(false);
          setExcludedRows(new Set());
          return;
        } else {
          newExcludedRows.add(row.id);
        }
      }

      setExcludedRows(newExcludedRows);
    } else if (isLastRowToBeSelected) {
      setIsSelectedAll(true);
    }
  };

  return useMemo(
    () =>
      (
        [
          enableRowSelection && {
            id: 'select',
            header: ({ table }) => {
              const selectedRowsCount = Object.keys(
                table.getState().rowSelection
              ).length;
              const isMultiPageSelectChecked =
                multiPageSelectManager?.isSelectedAll &&
                !multiPageSelectManager?.excludedRows.size;
              const isMultiPageSelectIndeterminate =
                (selectedRowsCount > 0 && selectedRowsCount < totalCount) ||
                (multiPageSelectManager?.isSelectedAll &&
                  !!multiPageSelectManager?.excludedRows.size);

              return (
                <IndeterminateCheckbox
                  pl="4"
                  isDisabled={isRowSelectionDisabled}
                  isChecked={
                    multiPageSelectManager
                      ? isMultiPageSelectChecked
                      : table.getIsAllRowsSelected()
                  }
                  isIndeterminate={
                    multiPageSelectManager
                      ? isMultiPageSelectIndeterminate
                      : hasServerSidePagination
                      ? Object.keys(table.getState().rowSelection).length > 0 &&
                        !table.getIsAllRowsSelected()
                      : table.getIsSomeRowsSelected()
                  }
                  onChange={(event) => {
                    if (multiPageSelectManager) {
                      onMultiPageSelectChange(table, multiPageSelectManager);
                    } else {
                      table.getToggleAllRowsSelectedHandler()(event);
                    }
                  }}
                  onKeyPress={(event) => {
                    if (multiPageSelectManager) {
                      onMultiPageSelectChange(table, multiPageSelectManager);
                    } else {
                      table.getToggleAllRowsSelectedHandler()(event);
                    }
                  }}
                  aria-label={'select all rows'}
                  height="100%"
                  zIndex={1}
                />
              );
            },
            cell: ({ row, table }) => (
              <IndeterminateCheckbox
                pl="4"
                height="100%"
                tabIndex={-1}
                isDisabled={isRowSelectionDisabled}
                isChecked={row.getIsSelected()}
                isIndeterminate={row.getIsSomeSelected()}
                onChange={(event) => {
                  if (multiPageSelectManager) {
                    onRowSelectChange({
                      event,
                      table,
                      row,
                      multiPageSelectManager,
                    });
                  } else {
                    row.getToggleSelectedHandler()(event);
                  }
                }}
                onKeyPress={(event) => {
                  if (multiPageSelectManager) {
                    onRowSelectChange({
                      event,
                      table,
                      row,
                      multiPageSelectManager,
                    });
                  } else {
                    row.getToggleSelectedHandler()(event);
                  }
                }}
                aria-label={
                  !row.getCanExpand()
                    ? `select row ${row.id}`
                    : 'select sub rows'
                }
                zIndex={1}
              />
            ),
            enableSorting: false,
          },
          ...columns,
          renderRowActions && {
            id: 'actions',
            header: 'Actions',
            meta: {
              actionsEnabled: true,
            },
            cell: renderRowActions,
          },
        ] as ColumnDef<Data>[]
      ).filter(Boolean),
    [
      data,
      columns,
      enableRowSelection,
      isRowSelectionDisabled,
      multiPageSelectManager,
    ]
  );
};

export const useServerSidePaginationAndSorting = ({
  initialPaginationState,
  query,
  variables,
  policy,
}: UseServerSidePaginationAndSorting) => {
  const pageIndex = initialPaginationState?.pageIndex || 0;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex,
    pageSize: initialPaginationState?.pageSize || 30,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const { data, refetch, ...rest } = query({
    variables: {
      itemsPerPage: pagination.pageSize,
      page: pagination.pageIndex + 1,
      ...variables,
    },
    fetchPolicy: policy || 'cache-first',
  });

  useEffect(() => {
    if (pagination.pageIndex || pagination.pageSize) {
      refetch({
        ...(pagination.pageSize && { itemsPerPage: pagination.pageSize }),
        page: pagination.pageIndex + 1,
      }).catch(() => {});
    }
  }, [pagination, variables?.filter]);

  const totalCount: number =
    (Object.values(data || {})[0] as any)?.paginationInfo?.totalCount ||
    pagination.pageSize;

  const pageCount = Math.ceil(totalCount / pagination.pageSize);

  return {
    pagination,
    setPagination,
    sorting,
    setSorting,
    pageCount,
    totalCount,
    data,
    refetch,
    ...rest,
  };
};
