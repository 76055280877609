import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';

interface PromptModalProps {
  onOk(): void;
  onCancel(): void;
  isOpen: boolean;
}

const PromptModal: React.FC<PromptModalProps> = ({
  onOk,
  onCancel,
  isOpen,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onCancel} isCentered>
      <ModalContent>
        <ModalBody p={6}>
          <Flex
            gap={2}
            p={6}
            bgColor="info.100"
            borderRadius="6px"
            mb={4}
            direction="column"
            alignItems="flex-start"
          >
            <Heading variant="h5" as="h5" fontSize="1.1rem">
              Are you sure you want to exit?
            </Heading>
          </Flex>
          <Flex justifyContent="space-between">
            <Button variant="outline" onClick={onCancel}>
              <Text as="span">Resume</Text>
            </Button>
            <Button
              isLoading={isLoading}
              variant="ghost"
              onClick={() => {
                setIsLoading(true);
                onOk();
              }}
            >
              <Text as="span">Save and Exit</Text>
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PromptModal;
