import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { WorkingLocation } from '@lon/shared/contexts';
import { SignAssignmentServices } from '@lon/shared/gql/types';
import {
  useGetFeedbacksAndScores,
  useRenderReportsLearnosity,
} from '@lon/suit/hooks';
import { getDefaultValuesForGradingForm } from '@lon/suit/utils';
import { types } from './duck';
import { Form } from './components';
import { reportId } from './duck/constants';

const LearnosityGrading: React.FC<types.LearnosityGradingProps> = ({
  isDa,
  backPath,
  isLti,
  isLearnosityLoading,
  setIsLearnosityLoading,
}) => {
  const { currentSchoolId } = useContext(WorkingLocation);

  const { studentId, sessionId, assignmentId } = useParams();

  useRenderReportsLearnosity({
    reportId,
    setIsLearnosityLoading,
    signRequestOptions: {
      variables: {
        params: {
          reportSignedRequestInput: {
            // uncomment for local env
            // domain:
            //   'local.stem.dev.stemscopes-v4-dev.aws.acceleratelearning.com',
            service: SignAssignmentServices.Reports,
            reports: JSON.stringify([
              {
                id: reportId,
                type: 'session-detail-by-item',
                user_id: studentId,
                session_id: sessionId,
              },
            ]),
            studentId: studentId as string,
            sessionId,
          },
        },
      },
    },
  });

  const { loading, items, feedbacks } = useGetFeedbacksAndScores({
    isDa,
    studentId,
    sessionId,
    schoolId: currentSchoolId,
    isLti,
  });

  const defaultValues = getDefaultValuesForGradingForm(items, feedbacks);

  // todo add loader
  if (isLearnosityLoading || loading) {
    return <></>;
  }

  return (
    <Form
      isLti={isLti}
      isDa={isDa}
      defaultValues={defaultValues}
      items={items}
      assignmentId={assignmentId}
      backPath={backPath}
    />
  );
};

export default LearnosityGrading;
