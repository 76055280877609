import { Standard } from '@lon/shared/types';
import {
  HideElement,
  Scope,
  TransformedElements,
  TransformedSection,
} from '@lon/suit/types';

// TODO: copied to shared folder and need to remove after content-player code merged with suit

export const filterStandards = (
  taxonomy?: Standard[],
  standardsJson?: Record<string, string>,
  state?: string
) =>
  taxonomy?.filter(
    (standard: { uuid: string }) =>
      standardsJson?.[standard.uuid] === state?.toLowerCase()
  );

export const transformElements = (
  scope?: Scope,
  standardsJson?: Record<string, string>,
  state?: string,
  isFactFluency?: boolean
): {
  sections: TransformedSection[];
  elementsBySection: TransformedElements;
} => {
  const sections =
    scope?.sections
      ?.filter((section) => !(section?.metadata?.hide === HideElement.Yes))
      .map((section) => {
        const processedElements = section?.elements?.map((item) => ({
          ...item,
          metadata: {
            ...(item?.metadata || {}),
            sectionTitle: section?.metadata?.sectionTitle,
            scopeDisplayName: scope?.displayName,
          },
          teacherView: {
            ...item.teacherView,
            taxonomy: filterStandards(
              item?.teacherView?.taxonomy,
              standardsJson,
              state
            ),
          },
          studentView: item.studentView.length
            ? item?.studentView?.map((item) => ({
                ...item,
                taxonomy: filterStandards(item?.taxonomy, standardsJson, state),
              }))
            : item.studentView,
        }));
        return {
          ...section,
          elements: processedElements,
        };
      }) || [];

  const factFluencySections =
    (isFactFluency &&
      scope?.sections
        ?.filter((section) => !(section?.metadata?.hide === HideElement.Yes))
        .map((section) => {
          const processedElements = [] as any[];

          const getValue = (obj: any) => {
            if (obj) {
              const array = Object.keys(obj);
              array.forEach((key) => {
                if (key === 'sections') {
                  obj[key]?.forEach((section: any) => getValue(section));
                }
                if (key === 'elements') {
                  obj?.elements.forEach((item: any) => {
                    processedElements.push({
                      ...item,
                      metadata: {
                        ...(item?.metadata || {}),
                        sectionTitle: section?.metadata?.sectionTitle,
                        scopeDisplayName: scope?.displayName,
                      },
                      teacherView: {
                        ...item.teacherView,
                        taxonomy: filterStandards(
                          item?.teacherView?.taxonomy,
                          standardsJson,
                          state
                        ),
                      },
                      studentView: item.studentView.length
                        ? item?.studentView?.map((item: any) => ({
                            ...item,
                            taxonomy: filterStandards(
                              item?.taxonomy,
                              standardsJson,
                              state
                            ),
                          }))
                        : item.studentView,
                    });
                  });
                }
              });
            }
          };

          getValue(section);

          return {
            ...section,
            elements: processedElements,
          };
        })) ||
    [];

  const home = scope
    ? {
        home: {
          [scope.home?.landingPage.identifier || '']: {
            sectionId: 'home',
            ...scope.home?.landingPage,
            teacherView:
              typeof scope.home?.landingPage?.teacherView === 'object' &&
              Object.keys(scope.home?.landingPage?.teacherView).length
                ? scope.home?.landingPage?.teacherView
                : scope.home?.landingPage?.elementFiles?.find(
                    (file) => file.href && !file.metadata?.fileType
                  ),
          },
          ...scope.home?.sideBar.reduce(
            (acc, element) => ({
              ...acc,
              [element.identifier]: {
                ...element,
                sectionId: 'home',
              },
            }),
            {}
          ),
        },
      }
    : {};

  const elementsBySection = (isFactFluency ? factFluencySections : sections)
    .length
    ? (isFactFluency ? factFluencySections : sections).reduce(
        (acc, section) => ({
          ...acc,
          ...home,
          [section.identifier]: section?.elements?.reduce(
            (acc, element) => ({
              ...acc,
              [element.identifier]: {
                ...element,
                sectionId: section.identifier,
                sectionTitle: section?.metadata?.sectionTitle || section?.title,
              },
            }),
            {}
          ),
        }),
        {}
      )
    : home;

  return {
    sections,
    elementsBySection,
  };
};
