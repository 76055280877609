import { Menu } from '../../../menu';
import {
  AssignToStudents,
  Comment,
  ContentMenu,
  FontLarger,
  FontSmaller,
  Highlighting,
  LanguageSwitcher,
  Planner,
  Print,
  ShowAnswers,
  Speech,
  Standards,
  ViewFiles,
  ViewType,
} from '../../../toolbar-actions';
import { uniqueId } from 'lodash-es';
import { useContext } from 'react';
import { isReviewSite } from '@lon/shared/constants';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import {
  useAuth,
  useGetIsLeaderSuitFlag,
  useQueryParams,
} from '@lon/shared/hooks';
import { RoleCodeEnum } from '@lon/shared/requests';
import { checkContentPlayer } from '@lon/shared/utils';
import { teacherView } from '@lon/suit/constants';
import { ScopeContext } from '@lon/suit/contexts';
import { useCheckIfContentExists } from '@lon/suit/hooks';
import { ScopeType, ViewType as View } from '@lon/suit/types';
import { actionsTypes } from './duck';

// TODO: remove when functionality will be ready
const show = false;

export const Actions: React.FC<actionsTypes.ActionsProps> = ({
  standards,
  printMethod,
  onViewTypeChangeComplete,
  iframeRef,
  hasStudentView,
  isKahootElement,
}) => {
  const [params] = useQueryParams();
  const viewType: View = params.type || teacherView;
  const contentExists = useCheckIfContentExists();
  const [{ user }] = useAuth();
  const { projectType } = useContext(ScopeContext);
  const { application } = useContext(WorkingLocation);
  const isContentPlayer = checkContentPlayer();
  const isParentSuite = application === ApplicationEnum.PARENT_SUIT;
  const isDailyNumeracy = projectType === ScopeType.DailyNumeracy;
  const isFactFluency = projectType === ScopeType.FactFluency;
  const isHowTo = projectType === ScopeType.HowTo;

  const isLeaderSuit = useGetIsLeaderSuitFlag();
  const isCampusLeaderSuit = application === ApplicationEnum.CAMPUS_LEADER_SUIT;
  const isCampusLeader = user?.userRoles?.find(
    (role) => RoleCodeEnum.CampusLeader === role.code
  );

  // Hide assignment button for leader suite and "Kahoot" element
  const isAssignButtonHidden = isLeaderSuit || isKahootElement;
  const isAddToPlanHidden = isCampusLeaderSuit && isCampusLeader;

  return (
    <Menu
      key={uniqueId()}
      popup
      hoverable={false}
      skippedKeyboardButtons={{
        ARROW_DOWN: true,
      }}
      gap="2"
      w="full"
      justifyContent={{ base: 'space-between', md: 'end' }}
    >
      {!isReviewSite() && !isContentPlayer && show && <FontLarger />}
      {!isReviewSite() && !isContentPlayer && show && <FontSmaller />}
      {!isReviewSite() && !isContentPlayer && show && <Highlighting />}
      {!isReviewSite() && !isContentPlayer && show && <Comment />}
      {!isReviewSite() && !isContentPlayer && <Speech />}
      {!isReviewSite() && !isContentPlayer && show && <LanguageSwitcher />}
      {!isReviewSite() &&
        !isContentPlayer &&
        !isAddToPlanHidden &&
        !isParentSuite &&
        !isFactFluency &&
        !isDailyNumeracy &&
        !isHowTo && <Planner />}
      {standards?.length &&
        !isContentPlayer &&
        !isFactFluency &&
        !isDailyNumeracy && <Standards standards={standards} />}
      {hasStudentView && !isDailyNumeracy && (
        <ViewType onViewTypeChangeComplete={onViewTypeChangeComplete} />
      )}
      {!isAssignButtonHidden &&
        !isContentPlayer &&
        !isParentSuite &&
        !isDailyNumeracy && <AssignToStudents viewType={viewType} />}
      {!isReviewSite() && !isContentPlayer && viewType !== teacherView && (
        <ShowAnswers />
      )}
      {viewType === teacherView && <ViewFiles />}
      {!isReviewSite() && !isContentPlayer && !isParentSuite && (
        <Print printMethod={printMethod} />
      )}
      {contentExists && !isContentPlayer && !isDailyNumeracy && (
        <ContentMenu iframeRef={iframeRef} />
      )}
    </Menu>
  );
};

export default Actions;
