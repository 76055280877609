import { Button, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Icon } from '@lon/shared/components';
import { Drawer } from './components';

const Filters: React.FC<{ defaultLevel: string }> = ({ defaultLevel }) => {
  const { onClose, isOpen, onOpen } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <Drawer
        isOpen={isOpen}
        handleClose={onClose}
        defaultLevel={defaultLevel}
      />
      <Button
        onClick={onOpen}
        color="primary.800"
        leftIcon={<Icon name="filter-empty-outlined" />}
        size="sm"
      >
        {t('reportsDashboard.filters')}
      </Button>
    </>
  );
};

export default Filters;
