import { Icon } from '@chakra-ui/react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { GradeTag } from 'libs/shared/components/src';
import { FC } from 'react';
import { minus } from '@lon/shared/assets';

const Grade: FC<{ grade: number | undefined }> = ({ grade }) => {
  switch (true) {
    case typeof grade === 'undefined':
      return (
        <GradeTag
          backgroundColor="primary.30"
          borderColor="primary.50"
          color="primary.800"
        >
          <Icon as={minus} fontSize="1.25rem" />
        </GradeTag>
      );
    case typeof grade === 'number' && grade >= 90:
      return (
        <GradeTag
          backgroundColor="olive.50"
          borderColor="green.extraLight"
          color="olive.100"
        >
          {grade}
        </GradeTag>
      );
    case typeof grade === 'number' && grade >= 80:
      return (
        <GradeTag
          backgroundColor="warning.extraLight"
          borderColor="yellow.dark"
          color="warning.text"
        >
          {grade}
        </GradeTag>
      );
    case typeof grade === 'number' && grade >= 70:
      return (
        <GradeTag
          backgroundColor="orange.extraLight"
          borderColor="orange.dark"
          color="orange.900"
        >
          {grade}
        </GradeTag>
      );
    case typeof grade === 'number' && grade < 70:
      return (
        <GradeTag
          backgroundColor="danger.extraLight"
          borderColor="danger.75"
          color="danger.text"
        >
          {grade}
        </GradeTag>
      );
    default:
      return (
        <GradeTag
          backgroundColor="primary.30"
          borderColor="primary.50"
          color="primary.800"
        >
          <Icon as={minus} fontSize="1.25rem" />
        </GradeTag>
      );
  }
};

export default Grade;
