import AssignmentPrompt from '../assignment-prompt';
import { Box, IconButton } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@lon/shared/components';
import { isDevMode } from '@lon/shared/constants';
import { StudentGradeContext, useFullScreen } from '@lon/shared/contexts';
import { useAuth, useIpadSafari } from '@lon/shared/hooks';
import { useResetAssignmentStatusMutation } from '@lon/shared/requests';
import { clearQueryCache } from '@lon/shared/utils';
import { StudentContentProps, useFetchContent } from './duck';

const StudentContent: React.FC<StudentContentProps> = ({
  refetch = () => {},
  contentUrl = '',
  learnosityReferenceId,
  redirectPath = '',
  onLoad,
  isContentWithoutLearnosity,
}) => {
  const [auth] = useAuth();
  const iframeRef = useFetchContent({ contentUrl, refetch });
  const [resetAssignmentStatus] = useResetAssignmentStatusMutation();
  const navigate = useNavigate();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { isFullScreen, setIsFullScreen } = useFullScreen();
  const [scrollInterval, setScrollInterval] = useState<NodeJS.Timeout | null>(
    null
  );
  const [canScrollUp, setCanScrollUp] = useState(false);
  const [canScrollDown, setCanScrollDown] = useState(false);
  const isIpadSafari = useIpadSafari();
  const studentContext = React.useContext(StudentGradeContext);
  const isStudentBelowK3 = studentContext.isStudentBelowK3;

  useEffect(() => {
    const handleScroll = () => {
      const parent = document.querySelector('.page-content');
      if (parent) {
        setCanScrollUp(parent.scrollTop > 0);
        setCanScrollDown(
          parent.scrollTop + parent.clientHeight < parent.scrollHeight
        );
      }
    };

    handleScroll();

    const parent = document.querySelector('.page-content');
    if (parent) {
      parent.addEventListener('scroll', handleScroll);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (parent) {
        parent.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isFullScreen]);

  const handleScrollUp = () => {
    const parent = document.querySelector('.page-content');
    if (parent) {
      parent.scrollTo({
        top: parent.scrollTop - 50,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollDown = () => {
    const parent = document.querySelector('.page-content');
    if (parent) {
      parent.scrollTo({
        top: parent.scrollTop + 50,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollUpContinuous = () => {
    const parent = document.querySelector('.page-content');
    if (scrollInterval) clearInterval(scrollInterval);

    if (parent && parent.scrollTop > 0) {
      const interval = setInterval(() => {
        if (parent.scrollTop > 0) {
          parent.scrollBy({ top: -100, behavior: 'smooth' });
        } else {
          clearInterval(interval);
        }
      }, 100);
      setScrollInterval(interval);
    }
  };

  const handleScrollDownContinuous = () => {
    const parent = document.querySelector('.page-content');
    if (scrollInterval) clearInterval(scrollInterval);

    if (
      parent &&
      parent.scrollTop + parent.clientHeight < parent.scrollHeight
    ) {
      const interval = setInterval(() => {
        if (parent.scrollTop + parent.clientHeight < parent.scrollHeight) {
          parent.scrollBy({ top: 100, behavior: 'smooth' });
        } else {
          clearInterval(interval);
        }
      }, 100);
      setScrollInterval(interval);
    }
  };

  const handleMouseDownUp = () => {
    handleScrollUpContinuous();
  };

  const handleMouseDownDown = () => {
    handleScrollDownContinuous();
  };

  const handleMouseUp = () => {
    if (scrollInterval) clearInterval(scrollInterval);
    setScrollInterval(null);
  };

  const onCloseRedirectHandler = () => {
    const body = iframeRef.current?.contentWindow?.document.body;
    const observer = new MutationObserver(() => {
      const closeButton = body?.querySelector(
        '.test-dialog-quit'
      ) as HTMLElement;
      if (closeButton) {
        closeButton.addEventListener('click', () => {
          if (redirectPath) {
            navigate(redirectPath);
          } else {
            window.location.reload();
          }
          observer.disconnect();
        });
      }
    });
    observer.observe(body as Node, { childList: true, subtree: true });
  };

  const resetAssignmentStatusHandler = () => {
    const body = iframeRef.current?.contentWindow?.document.body;
    const observer = new MutationObserver(() => {
      const submitButton = body?.querySelector(
        '.test-dialog-save-submit'
      ) as HTMLElement;

      if (submitButton) {
        submitButton.addEventListener('click', async () => {
          await resetAssignmentStatus({
            variables: {
              activityParams: {
                activityId: learnosityReferenceId || '',
                studentId: auth.user.userId,
              },
            },
          });

          clearQueryCache([
            'studentStudentAssignments',
            'districtStudentAssignments',
          ]);
          observer.disconnect();
        });
      }
    });
    observer.observe(body as Node, { childList: true, subtree: true });
  };

  useEffect(() => {
    const handleMessage = (message: any) => {
      if (
        iframeRef?.current &&
        message?.data?.type === 'learnosity-item-saved'
      ) {
        setHasUnsavedChanges(message?.data?.value);
      }

      if (
        iframeRef?.current &&
        message?.data?.type === 'resizeContent' &&
        message.data.payload.height
      ) {
        // TODO Remove extra 20px when the best solution is found
        iframeRef.current.height = message.data.payload.height + 20;
      }

      if (
        !isDevMode() &&
        iframeRef?.current &&
        message?.data?.type === 'dynamicWidthHeightCalculation' &&
        message?.data?.elemHeight &&
        message?.data?.dataPath
      ) {
        const tabTableFrame: HTMLIFrameElement | undefined | null =
          iframeRef.current.contentWindow?.document.querySelector(
            `[data-path="${message.data.dataPath}"]`
          );

        if (tabTableFrame) {
          const urlParams = new URLSearchParams(window.parent.location.search);
          const ttsEnabled = urlParams.get('tts') === 'ON';

          tabTableFrame.scrolling = 'no';
          tabTableFrame.height = ttsEnabled
            ? message.data.elemHeight + 53
            : message.data.elemHeight;
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, []);

  return (
    <Box h="full">
      {isFullScreen ? (
        <IconButton
          position={'absolute'}
          aria-label="full-screen"
          icon={<Icon name="closeOutlined" />}
          alignSelf="flex-end"
          mt={2}
          right={12}
          border={0}
          sx={{
            _hover: {
              bg: 'transparent',
              color: 'inherit',
            },
            _focus: {
              boxShadow: 'none',
            },
            _active: {
              bg: 'transparent',
            },
          }}
          onClick={() => setIsFullScreen(false)}
        />
      ) : (
        <IconButton
          position="absolute"
          aria-label="full-screen"
          icon={<Icon name="fullScreen" />}
          alignSelf="flex-end"
          mt={2}
          right={12}
          border={0}
          sx={{
            _hover: {
              bg: 'transparent',
              color: 'inherit',
            },
            _focus: {
              boxShadow: 'none',
            },
            _active: {
              bg: 'transparent',
            },
          }}
          onClick={() => setIsFullScreen(true)}
        />
      )}
      <Box display="flex">
        <Box
          as="iframe"
          ref={iframeRef}
          data-reference-id={learnosityReferenceId}
          title="Assignment"
          scrolling="no"
          w="full"
          minH="full"
          bgColor="white"
          data-testid="content-iframe"
          onLoad={() => {
            onCloseRedirectHandler();
            learnosityReferenceId && resetAssignmentStatusHandler();
            onLoad && onLoad();

            if (iframeRef?.current) {
              const allScripts =
                iframeRef.current.contentDocument?.querySelectorAll('script') ||
                [];
              const hasContentManagerScript = [...allScripts].find((script) =>
                script?.src.includes('content-manage')
              );

              const isSeparateHtmlPage =
                iframeRef?.current.src?.includes('.html');

              if (!hasContentManagerScript && isSeparateHtmlPage) {
                const script =
                  iframeRef.current.contentDocument?.createElement('script');

                if (!script) {
                  return;
                }
                script.src = isDevMode()
                  ? '/public/content-management.js'
                  : '/suite/content-manager.js';
                iframeRef.current.contentDocument?.body.appendChild(script);

                const link =
                  iframeRef.current.contentDocument?.createElement('link');
                if (!link) {
                  return;
                }
                link.href = isDevMode()
                  ? '/public/style1.css'
                  : '/suite/style1.css';
                link.type = 'text/css';
                link.rel = 'stylesheet';
                iframeRef.current.contentDocument?.head.append(link);

                const hasJQuery = [...allScripts].find((script) =>
                  script?.src.includes('jquery')
                );

                // non-lti-global_lms.js depends on jQuery
                if (hasJQuery) {
                  const scriptLtiGlobal =
                    iframeRef.current.contentDocument?.createElement('script');
                  if (!scriptLtiGlobal) {
                    return;
                  }
                  scriptLtiGlobal.src = isDevMode()
                    ? '/public/non-lti-global_lms.js'
                    : '/suite/non-lti-global_lms.js';
                  iframeRef.current.contentDocument?.body.appendChild(
                    scriptLtiGlobal
                  );
                }
              }
            }
          }}
        />
        {isFullScreen && isIpadSafari && (canScrollUp || canScrollDown) && (
          <Box
            display="flex"
            flexDirection="column"
            position="fixed"
            right={isStudentBelowK3 ? 6 : 8}
            zIndex={1}
            bottom="45%"
          >
            <IconButton
              mb={4}
              isDisabled={!canScrollUp}
              aria-label="scroll-up"
              icon={<Icon name="arrow-up" />}
              onClick={handleScrollUp}
              onMouseDown={handleMouseDownUp}
              onMouseUp={handleMouseUp}
              onTouchStart={handleMouseDownUp}
              onTouchEnd={handleMouseUp}
              sx={{
                _hover: {
                  bg: 'transparent',
                  color: 'inherit',
                },
                _focus: {
                  boxShadow: 'none',
                },
                _active: {
                  bg: 'transparent',
                },
              }}
            />
            <IconButton
              isDisabled={!canScrollDown}
              aria-label="scroll-down"
              icon={<Icon name="arrow-down" />}
              onClick={handleScrollDown}
              onMouseDown={handleMouseDownDown}
              onMouseUp={handleMouseUp}
              onTouchStart={handleMouseDownDown}
              onTouchEnd={handleMouseUp}
              sx={{
                _hover: {
                  bg: 'transparent',
                  color: 'inherit',
                },
                _focus: {
                  boxShadow: 'none',
                },
                _active: {
                  bg: 'transparent',
                },
              }}
            />
          </Box>
        )}
      </Box>
      <AssignmentPrompt
        hasUnsavedChanges={hasUnsavedChanges}
        onOk={(nextLocation) => {
          if (iframeRef.current) {
            iframeRef.current.contentWindow?.postMessage({
              type: 'saveActivity',
            });
            setTimeout(() => navigate(nextLocation), 500);
          }
        }}
      />
    </Box>
  );
};

export default StudentContent;
