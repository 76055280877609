import { ManageSubgroupsContext } from '../../duck';
import { SubgroupTabs } from '../subgroup-tabs';
import { FC, useContext, useMemo } from 'react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AsyncOutlet } from '@lon/shared/components';
import { IdPart } from '@lon/shared/enums';
import { useAuth } from '@lon/shared/hooks';
import { useGetClassSubgroupsQuery } from '@lon/shared/requests';
import { ClassContext } from '@lon/suit/contexts';
import { types } from './duck';

const StudentsPage: FC<types.StudentsPageProps> = ({
  openEditSubgroup,
  subgroups,
}) => {
  const { isMainTeacher } = useContext(ClassContext);
  const { setResetClassPagination, setResetSubgroupPagination } = useContext(
    ManageSubgroupsContext
  );
  const { subgroupId } = useParams();
  const navigate = useNavigate();
  const [{ user }] = useAuth();
  const { classId } = useParams() as { classId: string };

  const { data: classData } = useGetClassSubgroupsQuery({
    variables: {
      classId: `${IdPart.Classes}${classId}`,
    },
    skip: !classId,
  });

  const tabs = useMemo(
    () =>
      subgroups
        ?.map((subgroup: any) => ({
          id: subgroup?._id,
          title: subgroup?.name,
        }))
        .sort((a: any, b: any) => {
          const first = a.title || '';
          const second = b.title || '';
          // TODO: update hardcoded 'en' to Spanish when change to Spanish is implemented
          return first.localeCompare(second, 'en', { numeric: true });
        }),
    [
      classData?.class?.subgroups?.collection,
      isMainTeacher,
      user?.userId,
      subgroups,
    ]
  );

  return (
    <>
      <SubgroupTabs
        tabs={tabs as types.Tab[]}
        onChange={(id: string) => {
          navigate(`./${id}`);
          setResetClassPagination(true);
          setResetSubgroupPagination(true);
        }}
        activeTabIdx={subgroupId}
        openEditSubgroup={openEditSubgroup}
      />
      <AsyncOutlet />
    </>
  );
};

export default StudentsPage;
