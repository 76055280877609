import { Image as ChakraImage } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { types } from './duck';

const Image: React.FC<types.ImageProps> = ({ src, plugImageSrc, ...rest }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [src]);

  return (
    <ChakraImage
      src={
        hasError || !src ? plugImageSrc || 'suit/assets/missing-image.png' : src
      }
      onError={() => {
        if (!hasError) {
          setHasError(true);
        }
      }}
      {...rest}
    />
  );
};

export default Image;
