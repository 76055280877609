import { SimpleGrid, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { PageContent } from '@lon/shared/components';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum, DistrictPermission } from '@lon/shared/enums';
import { useAcl, useMediaQuery } from '@lon/shared/hooks';
import { BackButton, LinkCard } from '@lon/suit/components';
import { routes } from '@lon/suit/configs';

const AdditionalResources: React.FC = () => {
  const { t } = useTranslation();
  const [isBigMobile] = useMediaQuery(
    '(min-width: 376px) and (max-width: 767px)'
  );
  const { application } = useContext(WorkingLocation);
  const isTeacherSuite = application === ApplicationEnum.TEACHER_SUIT;
  const hasCoding = useAcl({
    permission: DistrictPermission.TEACHER_SUIT,
    module: 'coding',
    operation: 'view',
  });
  const [isLargerThan1680] = useMediaQuery('(min-width: 1680px)');

  return (
    <>
      <Helmet>
        <title>{t('additionalResources.title')}</title>
      </Helmet>
      <PageContent
        withoutSidebar
        pageTitle={t('additionalResources.heading')}
        headerElements={
          <BackButton
            label={t('backButton.default.label')}
            tooltipLabel={t('backButton.default.tooltipLabel')}
            to={routes.home}
          />
        }
      >
        <SimpleGrid
          spacing={6}
          columns={
            isBigMobile ? 2 : isLargerThan1680 ? 5 : { base: 1, md: 2, lg: 4 }
          }
          as="ul"
        >
          <LinkCard
            to={routes.assessments.root}
            hint={t('assessments.hint')}
            linkContent={
              <Text variant="h6" py={2} px={4} color="primary.800">
                {t('assessments.titleCard')}
              </Text>
            }
            imageUrl={'suit/assets/assessment-bank.png'}
            as="li"
          />
          <LinkCard
            to={generatePath(routes.additionalResources.help.root, {
              articleId: 'home',
            })}
            hint={t('helpPage.openHelpPage')}
            linkContent={
              <Text variant="h6" py={2} px={4} color="primary.800">
                {t('helpPage.title')}
              </Text>
            }
            imageUrl={'suit/assets/scope_card_image.png'}
            as="li"
          />
          {hasCoding && (
            <LinkCard
              to={routes.coding.root}
              hint={t('codingTool.openCodingTool')}
              linkContent={
                <Text variant="h6" py={2} px={4} color="primary.800">
                  {t('codingTool.title')}
                </Text>
              }
              imageUrl={'suit/assets/coding_tool.png'}
              as="li"
            />
          )}
          {isTeacherSuite && (
            <LinkCard
              to={routes.additionalResources.streaming.root}
              hint={t('streaming.openCodingTool')}
              linkContent={
                <Text variant="h6" py={2} px={4} color="primary.800">
                  {t('streaming.title')}
                </Text>
              }
              imageUrl={'suit/assets/streaming.png'}
              as="li"
            />
          )}
        </SimpleGrid>
      </PageContent>
    </>
  );
};

export default AdditionalResources;
