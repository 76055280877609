import StudentContentHeader from '../../../student-content-header/StudentContentHeader';
import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { StudentGradeContext, useFullScreen } from '@lon/shared/contexts';
import { types } from './duck';
import { LowerStudentWrapper, MiddleHighStudentWrapper } from './components';

const RightContent: React.FC<types.RightContentProps> = ({
  rightColumnComponent,
  pageContentProps,
  hasTabletPaddings,
  isEmpty,
  emptyMessage,
  headerElements,
  pageTitle,
  hasLeftColumnComponent,
  toolbar,
}) => {
  const { isStudentBelowK3 } = React.useContext(StudentGradeContext);
  const { isFullScreen } = useFullScreen();

  return (
    <Flex direction="column" w="full" as="main" id="main">
      {!isFullScreen && (
        <StudentContentHeader
          headerElements={headerElements}
          pageTitle={pageTitle}
          hasLeftColumnComponent={hasLeftColumnComponent}
        />
      )}
      {!isFullScreen && toolbar && <Box zIndex={1}>{toolbar}</Box>}
      {isStudentBelowK3 ? (
        <LowerStudentWrapper
          rightColumnComponent={rightColumnComponent}
          pageContentProps={pageContentProps}
          hasTabletPaddings={hasTabletPaddings}
          isEmpty={isEmpty}
          emptyMessage={emptyMessage}
        />
      ) : (
        <MiddleHighStudentWrapper
          rightColumnComponent={rightColumnComponent}
          pageContentProps={pageContentProps}
          hasTabletPaddings={hasTabletPaddings}
          isEmpty={isEmpty}
          emptyMessage={emptyMessage}
        />
      )}
    </Flex>
  );
};

export default RightContent;
