import {
  Button,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useContext } from 'react';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Icon, Select } from '@lon/shared/components';
import { ClassesContext, WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { LevelEnumType } from '@lon/shared/requests';

interface DrawerProps {
  handleClose: () => void;
  isOpen: boolean;
  defaultLevel: string;
}

interface FormValues {
  level: Record<string, string>;
  class: Record<string, string> | null;
}

const Drawer = ({ handleClose, isOpen, defaultLevel }: DrawerProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { application } = useContext(WorkingLocation);

  const level = searchParams.get('level') || '';
  const classParam = searchParams.get('class') || '';

  const options = Object.keys(LevelEnumType).map((key) => ({
    label: key.replace(/(\D)(\d)/, '$1 $2'),
    value: key,
  }));

  const { data, loading } = useContext(ClassesContext);
  const classesOptions = data?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const defaultValues = {
    level: level
      ? { label: level.replace(/(\D)(\d)/, '$1 $2'), value: level }
      : undefined,
    class: classesOptions?.find((item) => item.value === classParam),
  };

  const formProviderProps = useForm<FormValues>({
    defaultValues,
    mode: 'onSubmit',
  });

  const onReset = () => {
    const params = Object.fromEntries(searchParams.entries());
    delete params['standardsPage'];
    delete params['class'];

    setSearchParams({ ...params, level: defaultLevel, page: '1' });
    formProviderProps.reset();
    handleClose();
  };

  const onSubmit = (values: FormValues) => {
    const level = values?.level?.value;
    const classValue = values?.class?.value;

    const params = Object.fromEntries(searchParams.entries());
    formProviderProps.reset();
    delete params['standardsPage'];

    if (params.class && !classValue) {
      delete params.class;
    }

    setSearchParams({
      ...params,
      level,
      page: '1',
      ...(classValue ? { class: classValue } : {}),
    });
    handleClose();
  };

  return (
    <ChakraDrawer variant="formDrawer" isOpen={isOpen} onClose={handleClose}>
      <DrawerOverlay />
      <DrawerContent maxW="62.5rem">
        <DrawerHeader
          display="flex"
          alignItems="center"
          gap={4}
          px={{ xs: 4, b375: 6, md: 8 }}
        >
          <Text variant="h4" as="h2" flexGrow="2" noOfLines={1}>
            {t('reportsDashboard.filters')}
          </Text>
          <DrawerCloseButton top="initial" position="initial" />
        </DrawerHeader>
        <DrawerBody
          display="flex"
          flexDirection="column"
          alignItems="center"
          py={7}
        >
          <FormProvider {...formProviderProps}>
            <Flex
              as="form"
              onSubmit={formProviderProps.handleSubmit(onSubmit)}
              id="filters"
              w="full"
              justifyContent="center"
            >
              <SimpleGrid columns={2} spacing={6} maxW="764px" px={8} w="full">
                <Select
                  label={t('reportsDashboard.standardsLevel')}
                  name="level"
                  placeholder="Select Standard"
                  defaultValue={defaultValues.level}
                  onChange={(e) => {
                    if (e) {
                      formProviderProps.setValue('level', e, {
                        shouldDirty: true,
                      });
                    }
                  }}
                  options={options}
                />

                {application === ApplicationEnum.TEACHER_SUIT && (
                  <Select
                    label={t('reportsDashboard.class')}
                    name="class"
                    placeholder="Select Class"
                    defaultValue={defaultValues.class}
                    isClearable
                    showClearIndicator
                    isDisabled={loading}
                    onChange={(e) => {
                      formProviderProps.setValue('class', e, {
                        shouldDirty: true,
                      });
                    }}
                    options={classesOptions}
                  />
                )}
              </SimpleGrid>
            </Flex>
          </FormProvider>
        </DrawerBody>

        <DrawerFooter justifyContent="space-between" gap="4">
          <Flex gap="4">
            <Button
              leftIcon={<Icon name="chevron-left" />}
              onClick={handleClose}
              aria-label={t('reportsDashboard.backToReport')}
            >
              <Text as="span" isTruncated>
                {t('reportsDashboard.backToReport')}
              </Text>
            </Button>
            <Button
              onClick={onReset}
              aria-label={t('reportsDashboard.clearAll')}
            >
              <Text as="span" isTruncated>
                {t('reportsDashboard.clearAll')}
              </Text>
            </Button>
          </Flex>
          <Button
            variant="solid"
            aria-label={t('reportsDashboard.apply')}
            type="submit"
            form="filters"
          >
            <Text as="span" isTruncated>
              {t('reportsDashboard.apply')}
            </Text>
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </ChakraDrawer>
  );
};

export default Drawer;
