import { Box, Button, Flex, Skeleton, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { Icon } from '@lon/shared/components';
import {
  useGetSchoolIdsForPlanner as useGetSchoolIds,
  useGetTheme,
} from '@lon/shared/hooks';
import { useGetHowToScopesQuery } from '@lon/shared/requests';
import { getThemeStyleValue } from '@lon/shared/utils';
import { collapsibleSidebarPaddingRight } from '@lon/suit/components';
import { routes } from '@lon/suit/configs';

const Banner: React.FC = () => {
  const { t } = useTranslation();
  const currentTheme = useGetTheme();
  const accessibleSchoolIds = useGetSchoolIds();

  const { data, loading } = useGetHowToScopesQuery({
    variables: {
      schoolIds: accessibleSchoolIds,
    },
    skip: !accessibleSchoolIds?.length,
  });

  if (!loading && data?.howToScopes?.collection?.length === 0) {
    return <></>;
  }

  return loading ? (
    <Skeleton
      height="218px"
      borderRadius="md"
      ml={6}
      mr={collapsibleSidebarPaddingRight}
    />
  ) : (
    <Box
      background={getThemeStyleValue(
        'linear-gradient(271deg, rgba(19, 95, 55, 0.00) 21.56%, rgba(7, 97, 104, 0.80) 90.39%), linear-gradient(0deg, rgba(30, 65, 124, 0.00) -4.07%, rgba(29, 50, 87, 0.70) 50.03%), url(suit/assets/numeracy-banner.png) lightgray -26.632px -43.963px / 228.028% 120.167% no-repeat',
        'secondary.1000'
      )(currentTheme)}
      border={getThemeStyleValue('none', '1px solid #FFFFFF')(currentTheme)}
      color="white"
      borderRadius="md"
      boxShadow="pane"
      mt={4}
      mb={10}
      ml={6}
      mr={collapsibleSidebarPaddingRight}
    >
      <Flex p={6} gap={4} w="100%" flexDir="column">
        <Text variant="h6">{t('howToScope.bannerTitle')}</Text>
        {data?.howToScopes?.collection?.map((scope) => {
          return (
            <Button
              as={Link}
              to={generatePath(routes.scopes.show, {
                scopeId: scope.id,
                projectId: scope.projectId,
              })}
              rightIcon={
                <Icon name="arrow-right-outlined" width="18px" height="18px" />
              }
              variant="outlineDark"
              justifyContent="space-between"
              backgroundColor="transparent"
              px={3}
              py={1.5}
              h={8}
              minH={8}
            >
              <Text as="span" fontSize="0.875rem">
                {t('howToScope.curriculumArea', {
                  area: scope?.metadata?.curriculumArea,
                })}
              </Text>
            </Button>
          );
        })}
        <Text variant="s3" fontStyle="italic" whiteSpace="pre-wrap">
          {t('howToScope.bannerDescription')}
        </Text>
      </Flex>
    </Box>
  );
};

export default Banner;
