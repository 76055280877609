import { ElementContent } from '../../../../../../../element-content';
import { ErrorMessage } from '../../../../../../../errors';
import { Toolbar } from '../../../../../../../toolbar';
import { Box, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React, { RefObject, useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { showAnswers, teacherView } from '@lon/shared/constants';
import { ScopeContext } from '@lon/shared/contexts';
import { useAuth, useQueryParams } from '@lon/shared/hooks';
import { ScopeType, ViewType } from '@lon/shared/types';
import { checkContentPlayer } from '@lon/shared/utils';
import { landingPage, types } from './duck';
import {
  ContentPlayerBreadcrumbsWrapper,
  SuitBreadcrumbsWrapper,
} from './components';

const Main: React.FC<types.MainProps> = ({
  element,
  className,
  refetch,
  setAttempts,
  attempts,
  projectType,
  hasToolbar,
}) => {
  const [{ user }] = useAuth();
  const { t } = useTranslation();
  const [params] = useQueryParams();
  const isContentPlayer = checkContentPlayer();
  const { scopeId, projectId } = useParams() as {
    scopeId: string;
    projectId: string;
  };
  const iframeRef = useRef<HTMLIFrameElement>();
  const { metadata } = useContext(ScopeContext);

  const viewType: ViewType = params.get('type', teacherView);
  const answers = params.get('answers');
  const tts = params.get('tts');
  const studentElementId = params.get('studentElementId');
  const studentElement = studentElementId
    ? element.studentView.find((view) => view.identifier === studentElementId)
    : element.studentView?.[0] || {
        taxonomy: [],
        href: '',
        metadata: { activityTitle: '', hide: '' },
      };

  const currentView =
    viewType === teacherView ? element?.[viewType] : studentElement;
  const voiceSettings = localStorage.getItem('voicePreferences');
  const elementStandards = currentView?.taxonomy;
  const activityTitle = currentView?.metadata?.activityTitle;
  const isLandingPage = element.metadata?.sectionType === landingPage;
  const toolbarTitle = `${element?.metadata?.elementName || ''}${
    activityTitle ? ': ' + activityTitle : ''
  }`;
  const imageTag = process.env?.['NX_IMAGE_TAG'] || 1;
  const href = currentView?.href;
  const contentUrl = checkContentPlayer()
    ? href
    : `${href}${href?.includes('?') ? '&' : '?'}userType=${user?.type}&userId=${
        user?.userId
      }&v=${imageTag}`;
  const shouldHidePage = currentView?.metadata?.hide === 'Yes';
  const shouldDisplayToolbar = !isLandingPage && hasToolbar;

  const printFile = () => {
    const elementIcon = element?.metadata?.elementIconType || '';
    const elementName = element?.metadata?.elementName || '';
    const pageOrientation = currentView?.metadata?.page_orientation || '';
    const nameDateLine = currentView?.metadata?.nameDateLine || 'No';

    const urlWithElementDetails = `${contentUrl}&mode=print&printType=manual&editable=true&pageOrientation=${pageOrientation}&nameDateLine-${nameDateLine}&projectId=${projectId}&scopeId=${scopeId}&scopeName=${
      metadata?.displayName
    }&elementName=${elementName}&elementIconType=${elementIcon}&answers=${
      answers || showAnswers
    }&type=local_practice`;
    window.open(urlWithElementDetails, '_blank');
  };

  useEffect(() => {
    if (!contentUrl || shouldHidePage) {
      return;
    }
    const imageTag = process.env?.['NX_IMAGE_TAG'] || 1;

    const elementUrl = checkContentPlayer()
      ? contentUrl
      : `${contentUrl}&scopeId=${scopeId}&type=local_practice&v=${imageTag}`;

    const controller = new AbortController();

    const fetchIframe = async (controller: AbortController) => {
      try {
        // for local iframe update fetch url to "/public/test.html"
        const response = await fetch(elementUrl, {
          credentials: 'include',
          signal: controller.signal,
        });

        if (response.status === 401) {
          if (attempts < 3) {
            setAttempts(attempts + 1);
            refetch();
          }
        } else {
          if (iframeRef?.current) {
            iframeRef.current.src = elementUrl;
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchIframe(controller);

    return () => {
      controller.abort();
    };
  }, [attempts, contentUrl, shouldHidePage, answers, tts, voiceSettings]);

  const ToolbarPortal = () => {
    const mount = document.getElementById('outer-elements-portal');

    return ReactDOM.createPortal(
      <Toolbar
        title={projectType === ScopeType.Scope ? toolbarTitle : ''}
        standards={elementStandards}
        printMethod={printFile}
        hasStudentView={!isEmpty(element.studentView)}
        iframeRef={iframeRef as RefObject<HTMLIFrameElement>}
      />,
      mount as Element
    );
  };

  return (
    <Box
      className={className}
      flex="1"
      role="tabpanel"
      id="scope-element"
      aria-labelledby={element?.identifier}
    >
      {shouldHidePage ? (
        <ErrorMessage
          body={
            <Text as="p" variant="n3" pb="12">
              {t('errorMessage.404Body')}
            </Text>
          }
        />
      ) : (
        <Box pb={6} height="full">
          {/* @ts-ignore */}
          {shouldDisplayToolbar && <ToolbarPortal />}
          {!isLandingPage &&
            (!isContentPlayer ? (
              <SuitBreadcrumbsWrapper />
            ) : (
              <ContentPlayerBreadcrumbsWrapper />
            ))}
          <ElementContent
            element={element}
            iframeRef={iframeRef as RefObject<HTMLIFrameElement>}
          />
        </Box>
      )}
    </Box>
  );
};

export default Main;
