import { HStack, IconButton, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Icon, Select } from '@lon/shared/components';

interface Props {
  total: number;
  itemsPerPage: number;
}

const StandardsPagination: React.FC<Props> = ({ total, itemsPerPage }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({});

  const page = Number(searchParams.get('standards-page')) || 1;
  const numberOfPages = Math.ceil(total / itemsPerPage);
  const isDisableNext = page >= numberOfPages;
  const isDisabledPrev = page === 1;

  const handleChange = (page: number) => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...params, 'standards-page': page.toString() });
  };

  const options =
    numberOfPages === 0
      ? [{ label: 1, value: 1 }]
      : Array.from({ length: numberOfPages }).map((_, i) => ({
          label: i + 1,
          value: i + 1,
        }));

  const value = options.find((item) => item.value === page);
  return (
    <HStack
      spacing="6"
      justify="space-between"
      w="fit-content"
      alignItems="center"
    >
      <HStack spacing="2">
        <IconButton
          size="sm"
          variant="empty"
          boxShadow="none"
          aria-label={t('pagination.firstPage')}
          onClick={() => {
            if (!isDisabledPrev) {
              handleChange(1);
            }
          }}
          isDisabled={isDisabledPrev}
          icon={<Icon name="arr-double-left-outlined" />}
        />
        <IconButton
          size="sm"
          variant="empty"
          boxShadow="none"
          aria-label={t('pagination.previousPage')}
          onClick={() => {
            if (!isDisabledPrev) {
              const currentPage = page - 1;
              handleChange(currentPage);
            }
          }}
          isDisabled={isDisabledPrev}
          icon={<Icon name="arr-left-outlined" />}
        />
      </HStack>
      <HStack spacing={4} align="center" alignSelf="normal">
        <Text variant="n3" color="primary.800">
          {t('reportsDashboard.page')}
        </Text>
        <Select
          menuPortalTarget={document.body}
          formControlProps={{ maxW: '65px' }}
          size="s"
          value={value}
          onChange={(item) => {
            handleChange(Number(item?.value));
          }}
          isDisabled={options.length === 0}
          options={options}
        />
      </HStack>
      <HStack spacing="2">
        <IconButton
          size="sm"
          variant="empty"
          boxShadow="none"
          transform="scaleX(-1)"
          aria-label={t('pagination.nextPage')}
          colorScheme="whiteAlpha"
          onClick={() => {
            if (!isDisableNext) {
              const currentPage = page + 1;

              handleChange(currentPage);
            }
          }}
          isDisabled={isDisableNext}
          icon={<Icon name="arr-left-outlined" />}
        />
        <IconButton
          size="sm"
          variant="empty"
          boxShadow="none"
          transform="scaleX(-1)"
          aria-label={t('pagination.lastPage')}
          colorScheme="whiteAlpha"
          onClick={() => {
            if (!isDisableNext) {
              handleChange(numberOfPages);
            }
          }}
          isDisabled={isDisableNext}
          icon={<Icon name="arr-double-left-outlined" />}
        />
      </HStack>
    </HStack>
  );
};

export default StandardsPagination;
