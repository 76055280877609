import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useFullScreen } from '@lon/shared/contexts';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { types } from './duck';
import { LeftContent, RightContent } from './components';

const StudentPageContent: React.FC<types.StudentPageContentProps> = ({
  headerElements,
  pageTitle,
  leftColumnComponent,
  leftContentDesktopWidth = '300px',
  rightColumnComponent,
  pageContentProps,
  hasTabletPaddings = false,
  leftContentProps,
  leftContentWrapperProps,
  isEmpty = false,
  emptyMessage,
  toolbar,
}) => {
  const pageContentBorder = useThemeStyleValue('none', '1px solid #fff');
  const { isFullScreen } = useFullScreen();

  return (
    <Flex
      direction="column"
      position="relative"
      backgroundColor="blue.75"
      w="full"
      h="full"
      borderTop={pageContentBorder}
      borderRight={pageContentBorder}
      borderLeft={pageContentBorder}
    >
      <Flex position="absolute" left={0} right={0} top={0} bottom={0}>
        {!isFullScreen && leftColumnComponent && !isEmpty && (
          <LeftContent
            leftColumnComponent={leftColumnComponent}
            leftContentDesktopWidth={leftContentDesktopWidth}
            leftContentProps={leftContentProps}
            leftContentWrapperProps={leftContentWrapperProps}
          />
        )}
        {rightColumnComponent && (
          <RightContent
            headerElements={headerElements}
            pageTitle={pageTitle}
            hasLeftColumnComponent={!!leftColumnComponent}
            rightColumnComponent={rightColumnComponent}
            pageContentProps={pageContentProps}
            hasTabletPaddings={hasTabletPaddings}
            isEmpty={isEmpty}
            emptyMessage={emptyMessage}
            toolbar={toolbar}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default StudentPageContent;
