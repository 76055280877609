import { Flex, Tag, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useGradeLevel } from '@lon/shared/hooks';
import { Answer } from '@lon/shared/types';
import { generateGradeStyles } from '@lon/suit/utils';

interface GradingHeaderProps {
  response: Answer;
}

const GradingHeader: React.FC<GradingHeaderProps> = ({ response }) => {
  const { t } = useTranslation();
  const { isStudentBelowK3 } = useGradeLevel();

  const score =
    typeof response.maxScore === 'number' && typeof response.score === 'number'
      ? Math.round((response.score / response.maxScore) * 100)
      : null;

  return (
    <Flex gap="4" marginBlock="4" pos="relative">
      <Tag
        borderRadius="12px"
        size="md"
        fontWeight="normal"
        color="white"
        bgColor="primary.800"
        fontSize="0.75rem"
        lineHeight="0.75rem"
        border="none"
        padding="0.25rem 0.625rem"
        minH="22px"
      >
        {t('studentAssignmentDetails.grade')}
      </Tag>
      <Flex
        fontSize="0.75rem"
        lineHeight="0.75rem"
        alignItems="center"
        justifyContent="center"
        borderRadius="25px"
        w="36px"
        h="36px"
        pos="absolute"
        right="0"
        top={isStudentBelowK3 ? '-4px' : '-9px'}
        {...generateGradeStyles(score)}
      >
        <Text as="span" className="notranslate">
          {typeof score === 'number' ? score : '-'}
        </Text>
      </Flex>
    </Flex>
  );
};

export default GradingHeader;
