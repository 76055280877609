import { InputLayout } from '../input-layout';
import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
} from '@chakra-ui/react';
import { get } from 'lodash-es';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { textInputTypes } from './duck';

const ControlledInput: React.FC<textInputTypes.ControlledInputProps> = ({
  label,
  name,
  autoComplete = 'off',
  defaultValue = '',
  type = 'text',
  isRequired,
  inputLayoutProps,
  inputLeftElementProps,
  inputRightElementProps,
  inputLeftAddonProps,
  inputRightAddonProps,
  formLabelProps,
  innerRef,
  errors = {},
  value,
  onBlur,
  onChange,
  isDisabled,
  variant,
  isDisabledStable,
  inputAddons,
  rightLabel,
  errorPosition,
  ...rest
}) => {
  return (
    <>
      <InputLayout
        id={name || ''}
        label={label}
        isRequired={isRequired && !isDisabledStable}
        errorMessage={get(errors, `${name}.message`) as unknown as string}
        formLabelProps={formLabelProps}
        isDisabled={isDisabled}
        variant={variant}
        rightLabel={rightLabel as React.ReactElement}
        errorPosition={errorPosition}
        input={
          <InputGroup>
            {inputLeftElementProps && (
              <InputLeftElement
                pointerEvents="none"
                zIndex={1}
                {...inputLeftElementProps}
              />
            )}
            {inputLeftAddonProps && (
              <InputLeftAddon
                pointerEvents="none"
                zIndex={1}
                {...inputLeftAddonProps}
              />
            )}
            <Input
              ref={innerRef}
              type={type}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              autoComplete={autoComplete}
              isDisabled={isDisabled || isDisabledStable}
              name={name}
              {...rest}
              variant={isDisabledStable ? 'disabledStable' : variant}
            />
            {inputAddons}
            {inputRightElementProps && (
              <InputRightElement
                pointerEvents="none"
                zIndex={1}
                {...inputRightElementProps}
              />
            )}
            {inputRightAddonProps && (
              <InputRightAddon
                pointerEvents="none"
                zIndex={1}
                {...inputRightAddonProps}
              />
            )}
          </InputGroup>
        }
        {...inputLayoutProps}
      />
    </>
  );
};

const FormInput: React.FC<textInputTypes.TextInputProps> = ({
  name,
  defaultValue,
  ...rest
}) => {
  const { control } = useFormContext();

  const {
    field: { onBlur, onChange, name: fieldName, ref, value },
    formState: { errors },
  } = useController({ name, defaultValue, control });

  return (
    <ControlledInput
      onChange={onChange}
      onBlur={onBlur}
      name={fieldName}
      innerRef={ref}
      value={value}
      errors={errors}
      {...rest}
    />
  );
};

const TextInput: React.FC<textInputTypes.ControlledInputProps> = ({
  name,
  ...rest
}) => {
  if (name) {
    return <FormInput name={name} {...rest} />;
  }

  return <ControlledInput {...rest} />;
};

export default TextInput;
