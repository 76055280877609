export const countNestedItems = (
  obj: any
): { elementsCount: number; sectionsCount: number } => {
  let elementsCount = 0;
  let sectionsCount = 0;

  function traverse(node: any) {
    elementsCount += node.elements?.length || 0;
    sectionsCount += node.sections?.length || 0;

    node.sections?.forEach(traverse);
  }

  traverse(obj);

  return { elementsCount, sectionsCount };
};
