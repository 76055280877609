import React from 'react';

export const ManageSubgroupsContext = React.createContext({
  resetClassPagination: false,
  setResetClassPagination: () => {},
  resetSubgroupPagination: false,
  setResetSubgroupPagination: () => {},
} as {
  resetClassPagination: boolean;
  setResetClassPagination: React.Dispatch<React.SetStateAction<boolean>>;
  resetSubgroupPagination: boolean;
  setResetSubgroupPagination: React.Dispatch<React.SetStateAction<boolean>>;
});
