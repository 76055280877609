import { Grade } from '../components';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { t } from 'i18next';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  ConditionalWrapper,
  Link,
  SortingIcon,
} from 'libs/shared/components/src';
import camelCase from 'lodash-es/camelCase';
import mapKeys from 'lodash-es/mapKeys';
import { generatePath } from 'react-router-dom';
import { LevelEnumType, SortEnum } from '@lon/shared/requests';
import { parseJSON } from '@lon/shared/utils';
import { routes } from '@lon/suit/configs';
import { filterNullable } from '@lon/suit/utils';
import { Order, ReponseObject, StandardRecord, StudentRecord } from './types';

export const getColumns = (
  data: { standard: string; description?: string }[] = []
) => {
  const columnHelper = createColumnHelper<StudentRecord>();

  return [
    columnHelper.accessor('studentName', {
      cell: (info) => {
        const data = info?.row.original;

        return (
          <Box className="notranslate" py={2}>
            <Link
              tabIndex={-1}
              to={generatePath(routes.classes.studentAssignments, {
                studentId: `${data.studentId}`,
              })}
            >
              <Text as="span" variant="su4" _hover={{ textDecoration: 'none' }}>
                {info.getValue()}
              </Text>
            </Link>
            <Text variant="s2">{data?.studentUsername}</Text>
          </Box>
        );
      },
      header: (data) => (
        <Flex>
          <Text as="span">{t('reportsDashboard.student') as string}</Text>
          <SortingIcon column={data?.column as any} />
        </Flex>
      ),
    }),
    ...(data.map((item) =>
      columnHelper.accessor(item.standard, {
        id: item.standard,
        cell: (info) => {
          const score = (info.row.original?.[item.standard] as StandardRecord)
            ?.scorePct;
          const grade =
            typeof score === 'undefined' || score === null
              ? undefined
              : Math.round(Number(score));

          return (
            <Tooltip variant="dark" label={item.standard} aria-hidden={true}>
              <Flex justify="center">
                <Grade grade={grade} />
              </Flex>
            </Tooltip>
          );
        },
        header: (headerData) => {
          return (
            <ConditionalWrapper
              condition={!!item.description}
              wrapper={(children) => (
                <Tooltip
                  variant="dark"
                  label={item.description}
                  aria-hidden={true}
                >
                  {children}
                </Tooltip>
              )}
            >
              <Flex flexDirection="column" alignItems="center" gap={2} py={2}>
                <Text
                  color="primary.800"
                  css={{
                    writingMode: 'vertical-lr',
                  }}
                  transform="rotate(180deg)"
                >
                  {headerData?.header?.id}
                </Text>
                <SortingIcon column={headerData?.column as any} />
              </Flex>
            </ConditionalWrapper>
          );
        },
      })
    ) || []),
  ];
};

export const prepareData = (
  data: ReponseObject | undefined,
  firstElement:
    | {
        VarCharValue: string;
      }[]
    | undefined
): StandardRecord[] => {
  const preparedData = data?.Rows?.map((row) => {
    return row.Data?.reduce<Record<string, string>>((acc, val, idx) => {
      const camelCaseKey = camelCase(firstElement?.[idx]?.VarCharValue);

      const value = val.VarCharValue;

      if (typeof value === 'undefined') {
        return acc;
      }

      return {
        ...acc,
        [camelCaseKey]: value,
      };
    }, {});
  }).filter(filterNullable);

  return preparedData as StandardRecord[];
};

interface DetailedLevelData {
  activity_id_list: string[];
  assignment_id_list: string[];
  score_pct: number;
  standard_description_list: { standard: string; description: string }[];
  standard_level_filter: string;
}

export const mapStandardToStudent = (
  data: StandardRecord[]
): StudentRecord[] => {
  return data?.reduce<StudentRecord[]>((acc, val) => {
    const { studentId, studentUsername, studentName } = val;

    const detailedLevelData = parseJSON<DetailedLevelData[]>(
      val.detailedLevelData || '[]'
    );

    const studentIndex = acc.findIndex((item) => item.studentId === studentId);

    // case for invalid JSON data
    if (!Array.isArray(detailedLevelData)) {
      return acc;
    }

    const standardMap = detailedLevelData?.reduce((acc, val) => {
      const camelCaseObj = mapKeys(val, (v, k) => camelCase(k));

      const standardLevelFilter = camelCaseObj.standardLevelFilter as string;

      if (!standardLevelFilter) {
        return acc;
      }

      return {
        ...acc,
        [standardLevelFilter]: {
          ...camelCaseObj,
          standard: standardLevelFilter,
          description: (
            camelCaseObj?.standardDescriptionList as {
              standard: string;
              description: string;
            }[]
          )?.find((item) => item.standard === standardLevelFilter)?.description,
        },
      };
    }, {});

    if (studentIndex === -1) {
      acc.push({
        studentId,
        studentUsername,
        studentName,
        ...standardMap,
      });
    } else {
      acc[studentIndex] = {
        ...acc[studentIndex],
        ...standardMap,
      };
    }

    return acc;
  }, []);
};

export const getOrder = (
  sorting: {
    id: string;
    desc: boolean;
  }[]
): Order => {
  return sorting.length && sorting[0]?.id === 'studentName'
    ? {
        studentName: sorting[0].desc ? SortEnum.Desc : SortEnum.Asc,
      }
    : undefined;
};

export const isValidEnumKey = (
  key: string | undefined = ''
): key is keyof typeof LevelEnumType => {
  return key in LevelEnumType;
};

export const sortStandardsAlphaNumerically = (
  standards: {
    standard: string;
    description?: string | undefined;
  }[] = []
) => {
  return standards.sort((a, b) => {
    const regex = /(\d+|\D+)/g;
    const partsA = a?.standard.match(regex) || [];
    const partsB = b?.standard.match(regex) || [];

    // compare number parts and string parts of standards
    for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
      const partA = partsA[i] || '';
      const partB = partsB[i] || '';

      const numA = parseInt(partA, 10);
      const numB = parseInt(partB, 10);

      if (!isNaN(numA) && !isNaN(numB)) {
        if (numA !== numB) {
          return numA - numB;
        }
      } else {
        if (partA !== partB) {
          return partA.localeCompare(partB);
        }
      }
    }

    return 0;
  });
};

export const getEnumKeyFromNumber = (
  num: number
): keyof typeof LevelEnumType => {
  const key = `Level${num}` as keyof typeof LevelEnumType;

  if (key in LevelEnumType) {
    return key;
  }

  // the default level is Level5
  return 'Level5';
};
