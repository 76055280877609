import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from '@lon/shared/hooks';
import { useSignRequestForStudentAssignmentMutation } from '@lon/shared/requests';
import { skipTranslationForLernosityElements } from '@lon/shared/utils';
import {
  prepareLearnosityReportForGradingPage,
  replaceVideoLinks,
  resizeMejsVideos,
} from '@lon/suit/utils';
import { RenderReportsProps, hideInactiveQuestions } from './duck';

const useRenderReportsLearnosityByQuestions = ({
  setIsLearnosityCalled,
  setIsLearnosityLoading,
  signRequestOptions,
  originalActivityItems,
}: RenderReportsProps) => {
  const { t } = useTranslation();
  const [params] = useQueryParams();
  const currentItemIndex = Number(params.questionNumber) - 1 || 0;
  const [signRequest] = useSignRequestForStudentAssignmentMutation();

  const toast = useToast();
  const showGeneralError = () => {
    toast({
      title: t('systemMessages.038'),
      variant: 'error-light',
      isClosable: true,
    });
  };

  const reportsLoading = (numberOfReports: number) => {
    let numberOfLoadedReports = 0;

    return () => {
      numberOfLoadedReports += 1;

      if (numberOfLoadedReports === numberOfReports) {
        setIsLearnosityLoading(false);
      }
    };
  };

  useEffect(() => {
    let mounted = true;

    if (!signRequestOptions?.length || !originalActivityItems.length) {
      return;
    }

    setIsLearnosityCalled(true);
    setIsLearnosityLoading(true);
    const resizeObserver = new ResizeObserver(() => resizeMejsVideos());
    const videoObserver = replaceVideoLinks(document.body);

    Promise.all(signRequestOptions.map((option) => signRequest(option)))
      .then((reportSignedRequestsResp) => {
        if (!mounted) {
          return;
        }
        const checkIfReportsLoaded = reportsLoading(
          reportSignedRequestsResp.length
        );

        reportSignedRequestsResp.forEach((signedRequest, index) => {
          const reportSignedRequest = signedRequest?.data?.signRequest
            ?.signedRequest as string;

          if (!reportSignedRequest) {
            throw new Error();
          }

          const reportsApp = window.LearnosityReports.init(
            JSON.parse(reportSignedRequest),
            {
              readyListener() {
                const reportId = `report-${index + 1}`;
                const report = reportsApp.getReport(reportId);

                report.on('ready:itemsApi', function () {
                  if (!mounted) {
                    return;
                  }

                  skipTranslationForLernosityElements();
                  hideInactiveQuestions({
                    originalActivityItems,
                    currentItemIndex,
                    reportId,
                  });
                  prepareLearnosityReportForGradingPage(
                    reportId,
                    resizeObserver
                  );
                  checkIfReportsLoaded();
                });
              },
              errorListener() {
                if (!mounted) {
                  return;
                }

                checkIfReportsLoaded();
                showGeneralError();
              },
            }
          );
        });
      })
      .catch(() => {
        setIsLearnosityLoading(false);
        showGeneralError();
      });

    return () => {
      mounted = false;
      resizeObserver.disconnect();
      videoObserver.disconnect();
    };
  }, [signRequestOptions, originalActivityItems]);
};

export default useRenderReportsLearnosityByQuestions;
