import { ChakraProps } from '@chakra-ui/react';
import { Cell, Header } from '@tanstack/react-table';
import React from 'react';
import { getThemeStyleValue } from '@lon/shared/utils';
import { GetRightBorderWidth } from './types';
import { CHECKBOX_TH_WIDTH } from './constants';

const mask = 'a, button, input, select, textarea, [tabindex]';

export const getStickyStyles = (
  index: number,
  lastIndex: number,
  enableStickyColumns?: string,
  enableRowSelection?: boolean,
  isHeader?: boolean
): ChakraProps => {
  if (
    !enableStickyColumns ||
    (index !== 0 && index !== 1 && index !== lastIndex - 1)
  ) {
    return {};
  }
  const basicStyles: ChakraProps = {
    position: 'sticky',
    zIndex: isHeader ? 2 : 1,
  };

  if (enableStickyColumns !== 'last') {
    if (index === 0) {
      return { ...basicStyles, left: 0 };
    } else if (enableRowSelection && index === 1) {
      return {
        ...basicStyles,
        left: `${CHECKBOX_TH_WIDTH}px`,
      };
    }
  }

  if (index === lastIndex - 1 && enableStickyColumns !== 'first') {
    return { ...basicStyles, right: 0 };
  }

  return {};
};

export const shouldShowVerticalBorders = (
  index: number,
  enableVerticalBorders?: boolean,
  enableRowSelection?: boolean,
  enableStickyColumns?: 'last' | 'first' | 'both'
) => {
  if (!enableVerticalBorders || !enableStickyColumns) {
    return false;
  }

  if (index === 0) {
    return true;
  }

  if (index === 1 && enableRowSelection) {
    return true;
  }
  return false;
};

export const getRightBorderWidth: GetRightBorderWidth = ({
  enableRowSelection,
  isFirst,
  isLast,
  enableVerticalBorders,
}) => {
  if (enableRowSelection) {
    return enableVerticalBorders && !isFirst && !isLast ? 1 : 0;
  }

  if (!enableRowSelection) {
    return enableVerticalBorders && !isFirst && !isLast ? 1 : 0;
  }
  return 0;
};

export const getBorderBottomWidth = ({
  withoutBottomBorder,
  isLastRow,
  currentTheme,
}: any) => {
  if (withoutBottomBorder) {
    return 1;
  }

  return getThemeStyleValue(1, isLastRow ? 0 : 1)(currentTheme);
};

// function getNextFocusableElement(
//   container: HTMLElement,
//   currentElement: HTMLElement
// ): HTMLElement | null {
//   const focusableElements = Array.from(
//     container.querySelectorAll<HTMLElement>(mask)
//   );
//   const currentIndex = focusableElements.indexOf(currentElement);
//
//   if (currentIndex === -1) {
//     return null;
//   }
//
//   const nextIndex = (currentIndex + 1) % focusableElements.length;
//   return focusableElements[nextIndex];
// }

// function hasMultipleFocusableElements(container: HTMLElement): boolean {
//   const focusableElements = Array.from(
//     container.querySelectorAll<HTMLElement>(mask)
//   );
//
//   return focusableElements.length > 1;
// }

export function getFirstFocusableElement(
  container: HTMLElement
): HTMLElement | null {
  if (isElementFocusable(container)) {
    return container;
  }

  const descendants = container.querySelectorAll('*');
  for (const element of descendants) {
    if (element instanceof HTMLElement) {
      const focusableElement = getFirstFocusableElement(element);
      if (focusableElement) {
        return focusableElement;
      }
    }
  }

  return null;
}

const hasPositiveTabIndex = (element: HTMLElement): boolean => {
  const tabIndex = element.getAttribute('tabindex');

  if (tabIndex !== null) {
    const tabIndexValue = parseInt(tabIndex);
    return !isNaN(tabIndexValue) && tabIndexValue >= 0;
  }
  return true;
};

function getLastFocusableElement(container: HTMLElement): HTMLElement | null {
  if (isElementFocusable(container)) {
    return container;
  }

  const focusableElements = Array.from(
    container.querySelectorAll<HTMLElement>(mask)
  ).filter(hasPositiveTabIndex);

  if (focusableElements.length > 0) {
    return focusableElements[focusableElements.length - 1];
  }

  return null;
}

export function isElementFocusable(element: HTMLElement): boolean {
  // Check if the element is focusable by checking its tag name and attributes
  const tagName = element.tagName.toLowerCase();
  const isFocusableTag = [
    'a',
    'button',
    'input',
    'select',
    'textarea',
    'object',
    'iframe',
  ].includes(tagName);

  if (isFocusableTag) {
    return true;
  }

  // Check if the element has a tabindex attribute that makes it focusable
  const tabIndex = element.getAttribute('tabindex');
  if (tabIndex !== null) {
    const tabIndexValue = parseInt(tabIndex);
    return !isNaN(tabIndexValue) && tabIndexValue >= 0;
  }

  return false;
}
type HasFocusableElement = (
  container: HTMLElement,
  withContainer?: boolean
) => boolean;
export const hasFocusableElement: HasFocusableElement = (
  container,
  withContainer = true
) => {
  // Check if the container itself is focusable
  if (isElementFocusable(container) && withContainer) {
    return true;
  }

  // Check if any of its descendants are focusable
  const descendants = container.querySelectorAll('*');
  for (const element of descendants) {
    if (element instanceof HTMLElement) {
      if (hasFocusableElement(element)) {
        return true;
      }
    }
  }

  return false;
};

export const moveRight = ({
  elements,
  index,
}: {
  elements: any[];
  index: number;
}): HTMLElement | null => {
  let nextCellIndex = index === elements.length - 1 ? 0 : index + 1;

  while (nextCellIndex <= elements.length) {
    const nextCell = elements[nextCellIndex];
    const container = document.getElementById(nextCell.id);

    if (container && hasFocusableElement(container)) {
      const element = getFirstFocusableElement(container);
      element?.focus();
      return element;
    }
    if (nextCellIndex + 1 === elements.length) {
      nextCellIndex = 0;
    } else {
      nextCellIndex += 1;
    }
  }

  return null;
};

export const moveLeft = ({
  elements,
  index,
}: {
  elements: any[];
  index: number;
}): HTMLElement | null => {
  let prevCellIndex = index === 0 ? elements.length - 1 : index - 1;

  while (prevCellIndex >= 0) {
    const prevCell = elements[prevCellIndex];
    const container = document.getElementById(prevCell.id);

    if (container && hasFocusableElement(container)) {
      const element = getLastFocusableElement(container);
      element?.focus();
      return element;
    }

    if (prevCellIndex - 1 < 0) {
      prevCellIndex = elements.length - 1;
    } else {
      prevCellIndex -= 1;
    }
  }

  return null;
};

export const handleArrowsTableNavigation = ({
  event,
  rowIndex,
}: {
  event: React.KeyboardEvent<HTMLTableDataCellElement>;
  rowIndex: number;
}): void => {
  if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
    const containerActions = document.getElementById(`${rowIndex}_actions`);
    if (containerActions) {
      const input = containerActions.querySelector('button');
      if (input) {
        input.tabIndex = 0;
        input.focus();
      }
    }
  }
};

export const handleRowsNavigation = ({
  event,
  cell,
  index,
}: {
  event: React.KeyboardEvent<HTMLTableRowElement>;
  cell: any;
  index: number;
}): void => {
  const columnNameCheckbox =
    //  @ts-ignore
    cell.column.columnDef.accessorKey || cell.column.columnDef.id;
  const containerCheckbox = document.getElementById(
    `${index}_${columnNameCheckbox}`
  );
  const containerActions = document.getElementById(`${index}_actions`);
  if (event.code === 'Enter' || event.code === 'Space') {
    if (
      document.activeElement &&
      document.activeElement.tagName !== 'TR' &&
      document.activeElement.nodeName !== 'TR'
    ) {
      return;
    }

    if (containerCheckbox) {
      const input = containerCheckbox.querySelector('input');
      if (input) {
        input.focus();
      }
    }
    if (containerActions) {
      const input = containerActions.querySelector('button');
      if (input) {
        input.tabIndex = 0;
      }
    }
  }
  if (event.code === 'Escape') {
    const containerRow = document.getElementById(`${index}_row`);
    if (containerRow) {
      containerRow.focus();
    }
    if (containerActions) {
      const input = containerActions.querySelector('button');
      if (input) {
        input.tabIndex = -1;
      }
    }
  }
};

export const handleArrowsNavigation = <Data>({
  event,
  cell,
  index,
  rowIndex: currentRowIndex,
}: {
  event: React.KeyboardEvent<HTMLTableDataCellElement>;
  cell: Cell<Data, unknown>;
  index: number;
  rowIndex: number;
}): void => {
  const cellsInCurrentRow = cell.row.getAllCells();
  const context = cell.getContext();
  const { table } = context;
  const rows = table.getRowModel().rows;
  const columnName =
    //  @ts-ignore
    cell.column.columnDef.accessorKey || cell.column.columnDef.id;

  switch (event.key) {
    case 'ArrowUp': {
      // move to column element if it's the first row
      if (currentRowIndex === 0) {
        const headerElement = document.getElementById(columnName);
        if (!!headerElement && hasFocusableElement(headerElement)) {
          const focusableElement = getFirstFocusableElement(headerElement);
          focusableElement?.focus();
          return;
        }
      }

      let prevRowIndex =
        currentRowIndex === 0 ? rows.length - 1 : currentRowIndex - 1;

      while (prevRowIndex >= 0) {
        const container = document.getElementById(
          `${prevRowIndex}_${columnName}`
        );

        if (container && hasFocusableElement(container)) {
          const element = getFirstFocusableElement(container);
          element?.focus();
          break;
        }

        if (prevRowIndex - 1 < 0) {
          prevRowIndex = rows.length - 1;
        } else {
          prevRowIndex -= 1;
        }
      }
      break;
    }
    case 'ArrowDown': {
      // move to column element if it's the last row
      if (currentRowIndex === rows.length - 1) {
        const headerElement = document.getElementById(columnName);
        if (!!headerElement && hasFocusableElement(headerElement)) {
          const element = getFirstFocusableElement(headerElement);
          element?.focus();
          return;
        }
      }

      let nextRowIndex =
        currentRowIndex === rows.length - 1 ? 0 : currentRowIndex + 1;

      while (nextRowIndex <= rows.length) {
        const container = document.getElementById(
          `${nextRowIndex}_${columnName}`
        );

        if (container && hasFocusableElement(container)) {
          const element = getFirstFocusableElement(container);
          element?.focus();
          break;
        }
        if (nextRowIndex + 1 === rows.length) {
          nextRowIndex = 0;
        } else {
          nextRowIndex += 1;
        }
      }
      break;
    }
    case 'ArrowLeft': {
      moveLeft({ elements: cellsInCurrentRow, index });
      break;
    }
    case 'ArrowRight': {
      moveRight({
        elements: cellsInCurrentRow,
        index,
      });

      break;
    }
    default:
      return;
  }
};

export const handleHeadArrowsNavigation = <Data extends object>({
  event,
  index,
  header,
}: {
  event: React.KeyboardEvent<HTMLTableDataCellElement>;
  header: Header<Data, unknown>;
  index: number;
}): void => {
  const {
    headerGroup: { headers },
  } = header;
  const context = header.getContext();
  const { table } = context;
  const rows = table.getRowModel().rows;

  switch (event.code) {
    case 'ArrowUp': {
      let prevRowIndex = rows.length - 1;
      while (prevRowIndex <= rows.length) {
        const container = document.getElementById(
          `${prevRowIndex}_${header.id}`
        );

        if (container && hasFocusableElement(container)) {
          const element = getFirstFocusableElement(container);
          element?.focus();
          break;
        }

        if (prevRowIndex - 1 < 0) {
          return;
        } else {
          prevRowIndex -= 1;
        }
      }
      break;
    }
    case 'ArrowDown': {
      let nextRowIndex = 0;

      while (nextRowIndex <= rows.length) {
        const container = document.getElementById(
          `${nextRowIndex}_${header.id}`
        );

        if (container && hasFocusableElement(container)) {
          const element = getFirstFocusableElement(container);
          element?.focus();
          break;
        }

        if (nextRowIndex + 1 === rows.length) {
          return;
        } else {
          nextRowIndex += 1;
        }
      }

      break;
    }
    case 'ArrowLeft': {
      moveLeft({ elements: headers, index });
      break;
    }
    case 'ArrowRight': {
      moveRight({
        elements: headers,
        index,
      });

      break;
    }
    case 'Enter': {
      header.column.getToggleSortingHandler()?.(event);
      break;
    }
    case 'Space': {
      event.preventDefault();
      event.stopPropagation();
      header.column.getToggleSortingHandler()?.(event);
      break;
    }
    default:
      return;
  }
};
