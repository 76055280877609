import { customSort } from './customSort';
import { stateList } from './stateList';

export const statesShortList = customSort(
  stateList().map(({ value }) => ({
    value: value,
    label: value,
  })),
  'label'
);
