import { Section } from '../../duck';
import { Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@lon/shared/components';
import { routes } from '@lon/suit/configs';

interface Props {
  level: number;
  items?: Section[];
  sectionId?: string;
}

const Items: FC<Props> = ({ level, items, sectionId }) => {
  const { projectId, scopeId } = useParams();
  const navigate = useNavigate();

  return (
    <Flex
      direction="column"
      gap={4}
      w="full"
      borderTopWidth="1px"
      borderColor="secondary.200"
    >
      {items?.map((item: any, index: number) => (
        <Flex
          key={item.identifier}
          alignItems="center"
          cursor="pointer"
          onClick={() =>
            navigate(
              generatePath(routes.scopes.element, {
                projectId,
                scopeId,
                sectionId,
                elementId: item.identifier,
              })
            )
          }
          pl={`${level * 2}rem`}
          color="primary.800"
          pt={index === 0 ? 4 : undefined}
          pb={index === items?.length - 1 ? 4 : undefined}
          gap="8px"
        >
          <Icon name="readOutlined" size="lg" />
          <Text textDecoration="underline" variant="n5">
            {item?.metadata?.elementName}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default Items;
