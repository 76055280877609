import { Flex, IconButton } from '@chakra-ui/react';
import { t } from 'i18next';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Icon } from '@lon/shared/components';
import { useThemeStyleValue } from '@lon/shared/hooks';

interface Props {
  handleNextPage: () => void;
  handlePrevPage: () => void;
  isDisableNext: boolean;
  isDisabledPrev: boolean;
}
const Pagination: React.FC<Props> = ({
  handleNextPage,
  handlePrevPage,
  isDisableNext,
  isDisabledPrev,
}) => {
  const themeBorderColor = useThemeStyleValue('secondary.200', 'white');

  return (
    <Flex
      backgroundColor="white"
      justifyContent="center"
      alignItems="center"
      boxShadow="0px 5px 20px -2px rgba(43, 54, 70, 0.07)"
      border="1px solid"
      borderTop="none"
      borderColor={themeBorderColor}
      borderRadius="0 0 0.375rem 0.375rem"
      p={2}
    >
      <IconButton
        size="md"
        variant="empty"
        boxShadow="none"
        aria-label={t('pagination.previousPage')}
        onClick={() => {
          handlePrevPage();
        }}
        isDisabled={isDisabledPrev}
        icon={<Icon name="arr-left-outlined" />}
      />
      <IconButton
        size="md"
        variant="empty"
        boxShadow="none"
        transform="scaleX(-1)"
        aria-label={t('pagination.nextPage')}
        colorScheme="whiteAlpha"
        onClick={() => {
          handleNextPage();
        }}
        isDisabled={isDisableNext}
        icon={<Icon name="arr-left-outlined" />}
      />
    </Flex>
  );
};

export default Pagination;
