import {
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Icon } from 'libs/shared/components/src';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@lon/shared/hooks';

const ReportLegend = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 767px)');

  return (
    <>
      <IconButton
        size="sm"
        minW="42px"
        minH="32px"
        height="unset"
        variant="outline"
        aria-label={t('reportsDashboard.openLegend')}
        onClick={onOpen}
        borderRadius="4px"
        color="primary.800"
        isDisabled
      >
        <Icon name="info-circle" />
      </IconButton>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        {...(isMobile ? { size: 'full' } : {})}
      >
        <ModalContent
          data-focus-visible-disabled
          maxW={{ b768: '700px' }}
          borderWidth={{ base: 0, b768: '15px' }}
          borderColor="secondary.50"
          borderRadius={{ base: 0, b768: '20px' }}
        >
          <ModalHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={6}
            py={4}
            maxH="unset"
          >
            <Text variant="h5" fontSize="20px">
              {t('reportsDashboard.legend')}
            </Text>
            <ModalCloseButton position="unset" w="50px" h="50px" />
          </ModalHeader>
          <ModalBody p={0}>
            <Flex direction={{ base: 'column', b768: 'row' }} p={4}>
              Some info
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReportLegend;
