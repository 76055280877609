import { StudentAssignmentStatusTransformed } from '@lon/shared/enums';
import { ShareResults } from '@lon/shared/requests';

export const isAssignmentUnavailable = ({
  transformedStatus,
  shareResults,
  isArchived,
  isActiveAssignment,
  isParent,
}: {
  transformedStatus: StudentAssignmentStatusTransformed | null;
  shareResults?: string | null;
  isArchived?: boolean | null;
  isActiveAssignment?: boolean;
  isParent?: boolean;
}) =>
  (transformedStatus === StudentAssignmentStatusTransformed.Graded &&
    shareResults === ShareResults.Disabled) ||
  transformedStatus === StudentAssignmentStatusTransformed.ToBeGraded ||
  (isArchived &&
    !(
      transformedStatus === StudentAssignmentStatusTransformed.Graded &&
      shareResults !== ShareResults.Disabled
    )) ||
  (typeof isActiveAssignment === 'boolean' &&
    !isActiveAssignment &&
    !(
      isParent &&
      transformedStatus === StudentAssignmentStatusTransformed.Graded &&
      shareResults !== ShareResults.Disabled
    )) ||
  (isParent && transformedStatus !== StudentAssignmentStatusTransformed.Graded);
