import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { ReactNode, RefObject, useEffect, useRef } from 'react';

type PopoverTooltipPropsType = {
  children: ReactNode;
  label: string;
  hasArrow?: boolean;
  variant?: string;
  closeOnEsc?: boolean;
};

export const PopoverTooltip: React.FC<PopoverTooltipPropsType> = ({
  children,
  label,
  hasArrow,
  closeOnEsc,
  variant = 'tooltipLight',
}) => {
  const initRef: RefObject<HTMLDivElement> = useRef(null);
  const [isEditing, setIsEditing] = useBoolean();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (initRef.current && isEditing && closeOnEsc) {
      timeout = setTimeout(() => {
        initRef.current?.focus();
      }, 100);
    }

    return () => clearTimeout(timeout);
  }, [isEditing]);

  return (
    <Popover
      trigger="hover"
      variant={variant}
      initialFocusRef={initRef}
      onOpen={setIsEditing.on}
      onClose={setIsEditing.off}
      isLazy
      lazyBehavior="keepMounted"
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent w="auto" maxW="20rem" ref={initRef}>
        {hasArrow && <PopoverArrow />}
        <PopoverBody>
          <Text whiteSpace="pre-line" variant="s2">
            {label}
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default PopoverTooltip;
