import { ContentWrapperProps } from '../../duck/types';
import { Box, Flex } from '@chakra-ui/react';
import { Footer } from '@lon/shared/components';
import {
  isMobileDevice,
  sxRoundedContentScrollbar,
  sxRoundedHighContrastScrollbar,
} from '@lon/shared/constants';
import { useFullScreen } from '@lon/shared/contexts';
import {
  useAuth,
  useMediaQuery,
  useScrollExisting,
  useThemeStyleValue,
} from '@lon/shared/hooks';

const LowerStudentWrapper: React.FC<ContentWrapperProps> = ({
  rightColumnComponent,
  pageContentProps,
  hasTabletPaddings,
  isEmpty,
  emptyMessage,
}) => {
  const {
    contentRef,
    contentScrolls: { vertical: hasContentScroll },
  } = useScrollExisting({
    horizontal: false,
  });

  const [{ user }] = useAuth();
  const [isTablet] = useMediaQuery('(min-width: 768px)');
  const { isFullScreen } = useFullScreen();
  const scrollbar = useThemeStyleValue(
    sxRoundedContentScrollbar,
    sxRoundedHighContrastScrollbar
  );

  return (
    <Flex
      ref={contentRef}
      flexGrow={1}
      className="page-content"
      flexDir="column"
      overflowY="auto"
      backgroundColor={useThemeStyleValue('none', 'secondary.950')}
      sx={isMobileDevice ? {} : scrollbar}
    >
      <Flex
        position="relative"
        mr={
          !isMobileDevice && hasContentScroll
            ? {
                base: hasTabletPaddings ? 1 : 0,
                md: hasTabletPaddings ? 'calc(var(--chakra-space-1) * 1.4)' : 0,
                lg: 'calc(var(--chakra-space-1) * 2.4)',
              }
            : {}
        }
        pr={{
          base: hasTabletPaddings
            ? isEmpty
              ? 0
              : hasContentScroll && !isMobileDevice
              ? 1
              : 4
            : 0,
          md:
            hasTabletPaddings || isEmpty
              ? hasContentScroll && !isMobileDevice
                ? 'calc(var(--chakra-space-1) * 1.4)'
                : 6
              : 0,
          lg: isFullScreen
            ? 0
            : hasContentScroll && !isMobileDevice
            ? 'calc(var(--chakra-space-1) * 2.4)'
            : 8,
        }}
        pt={{
          base: isEmpty ? 0 : 4,
          md: 6,
          lg: isFullScreen ? 0 : 8,
        }}
        pl={{
          base: hasTabletPaddings ? (isEmpty ? 0 : 4) : 0,
          md: hasTabletPaddings || isEmpty ? 6 : 0,
          lg: isFullScreen ? 0 : 8,
        }}
        pb={{ base: 0, md: 6, lg: isFullScreen ? 0 : 8 }}
        gap={{ base: 4, md: 0 }}
        direction="column"
        justify="space-between"
        flexGrow={1}
        {...pageContentProps}
      >
        {isEmpty && emptyMessage ? emptyMessage : rightColumnComponent}
      </Flex>
      {!isTablet && !user.impersonated && (
        <Box mt="4">
          <Footer />
        </Box>
      )}
    </Flex>
  );
};

export default LowerStudentWrapper;
