import useMediaQuery from '../use-media-query';
import { useEffect, useState } from 'react';

const useIpadSafari = () => {
  const [isIpadSafari, setIsIpadSafari] = useState(false);
  const [isTabletResolution] = useMediaQuery(
    '(min-width: 600px) and (max-width: 1700px)'
  );

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isIpad =
      /iPad/.test(userAgent) ||
      (/Macintosh/.test(userAgent) &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 1);
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
    // Additional check to ensure it's not a MacBook
    const isMacBook =
      /Macintosh/.test(userAgent) &&
      !/iPad/.test(userAgent) &&
      !navigator.maxTouchPoints;

    if (isIpad && isSafari && isTabletResolution && !isMacBook) {
      setIsIpadSafari(true);
    }
  }, [isTabletResolution]);

  return isIpadSafari;
};

export default useIpadSafari;
