import { Icon } from '../../../icon';
import { RequiredNotification } from '../../../required-notificaton';
import { useToast } from '../../../use-toast';
import { useReactiveVar } from '@apollo/client';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import axios from 'axios';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { sxLightScrollBar } from '@lon/shared/constants';
import { WorkingLocation } from '@lon/shared/contexts';
import { useAuth, useMediaQuery, useThemeStyleValue } from '@lon/shared/hooks';
import { useDistrictQuery, useGetSchoolQuery } from '@lon/shared/requests';
import { FEEDBACK_OPEN } from '@lon/shared/utils';
import { FEEDBACK_URL, getValidation } from './duck';
import Form from './components';

const Feedback: React.FC<{ isAdmin?: boolean }> = ({ isAdmin }) => {
  const { t } = useTranslation();
  const [isTablet] = useMediaQuery('(min-width: 768px)');
  const [{ user }] = useAuth();
  const [loading, setLoading] = React.useState(false);
  const toast = useToast();
  const isFeedbackOpened = useReactiveVar(FEEDBACK_OPEN);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const { application, currentSchoolId } = React.useContext(WorkingLocation);
  const { data } = useDistrictQuery({
    variables: {
      id: `/api/organization-management/api/districts/${user?.districtId}`,
    },
    skip: !user?.districtId,
  });

  const { data: schoolData } = useGetSchoolQuery({
    skip: !currentSchoolId,
    variables: {
      id: `/api/organization-management/api/schools/${currentSchoolId}`,
    },
  });

  const { validationSchema, defaultValues } = getValidation(isAdmin);
  const formProviderProps = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    mode: 'onSubmit',
  });

  const comments = formProviderProps.watch('comments');
  const reason = formProviderProps.watch('reason');
  const product = formProviderProps.watch('product');

  const onCloseHandler = () => {
    formProviderProps.reset({});
    FEEDBACK_OPEN(false);
    setTimeout(() => {
      buttonRef.current?.blur();
    }, 0);
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      axios
        .post(FEEDBACK_URL, {
          district: data?.district?.externalId || 'School Not Listed',
          issue: values?.reason,
          email: values?.email,
          first_name: user?.firstName,
          last_name: user?.lastName,
          comments:
            application?.toLowerCase() === 'admin'
              ? values?.comments
              : `School: ${
                  schoolData?.school?.globalName || ''
                }(${currentSchoolId})
          User: ${user?.firstName} ${user?.lastName} (${user?.username})
          Email: ${values?.email}
          Name: ${user?.firstName} ${user?.lastName}
          Type: ${values?.reason}
          Context: ${window.location.href}
          Comments: ${values?.comments}`,
          product_type:
            application?.toLowerCase() === 'admin'
              ? values?.product
              : 'STEMscopes 4.0',
          role: application?.toLowerCase() === 'admin' ? 'admin' : 'staff',
        })
        .then((data) => {
          if (data?.status === 200) {
            setLoading(false);
            onCloseHandler();
            toast({
              title: t('footer.feedbackForm.successTitle'),
              description: t('footer.feedbackForm.successDescription'),
              variant: 'success-light',
            });
          } else {
            toast({
              title: t('footer.feedbackForm.errorTitle'),
              description: t('footer.feedbackForm.errorDescription'),
              variant: 'error-light',
            });
          }
        });
    } catch (e) {
      setLoading(false);
      toast({
        title: t('footer.feedbackForm.errorTitle'),
        description: t('footer.feedbackForm.errorDescription'),
        variant: 'error-light',
      });
    }
  };

  return (
    <Box>
      <Tooltip
        variant="dark"
        label={t('footer.feedbackTooltip')}
        aria-hidden={true}
      >
        <Button
          ref={buttonRef}
          aria-label={t('footer.feedbackTooltip')}
          variant={useThemeStyleValue('ghost', 'outline')}
          boxShadow="0px 5px 20px -2px #2B364612"
          onClick={() => FEEDBACK_OPEN(true)}
          size={!isTablet ? 'md' : 'sm'}
          px={!isTablet ? 1 : 2}
        >
          <Icon
            name="comment"
            {...(!isTablet && { width: '20px', height: '20px' })}
          />
          <Text as="span" isTruncated ml="1.5">
            {t('footer.feedback')}
          </Text>
        </Button>
      </Tooltip>
      {isFeedbackOpened ? (
        <Drawer
          size="xl"
          placement="right"
          onClose={onCloseHandler}
          isOpen={isFeedbackOpened}
        >
          <FormProvider {...formProviderProps}>
            <DrawerOverlay />
            <Box
              as="form"
              noValidate
              onSubmit={formProviderProps.handleSubmit(handleSubmit)}
            >
              <DrawerContent
                borderRadius="none"
                boxShadow="0px 3px 20px rgba(43, 54, 70, 0.2) !important"
              >
                <DrawerCloseButton size="lg" color="blue.800" top={5} />
                <DrawerHeader px={8} py={4} borderBottomWidth="1px">
                  <Text
                    my={2}
                    variant="h4"
                    fontSize="1.25rem"
                    color="primary.800"
                  >
                    {t('footer.feedbackForm.title')}
                  </Text>
                </DrawerHeader>
                <Box
                  borderBottomWidth="1px"
                  borderColor="secondary.200"
                  px={{ base: 10, md: '150px' }}
                  py={6}
                >
                  <Text variant="s3" color="primary.400">
                    <Trans i18nKey={t('footer.feedbackForm.header')} />
                  </Text>
                  <RequiredNotification />
                </Box>
                <DrawerBody
                  color="primary.800"
                  display="flex"
                  px={0}
                  flexDirection="column"
                  css={sxLightScrollBar}
                >
                  <Form isAdmin={isAdmin} />
                </DrawerBody>
                <DrawerFooter
                  borderTopWidth="1px"
                  justifyContent="space-between"
                >
                  <Button
                    variant="outline"
                    leftIcon={<Icon name="arr-left-outlined" />}
                    onClick={onCloseHandler}
                  >
                    {t('footer.feedbackForm.cancel')}
                  </Button>
                  <Button
                    variant="solid"
                    type="submit"
                    isLoading={loading}
                    isDisabled={!comments && !reason && !product}
                    leftIcon={<Icon name="check-outlined" />}
                  >
                    {t('footer.feedbackForm.submit')}
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Box>
          </FormProvider>
        </Drawer>
      ) : (
        <></>
      )}
    </Box>
  );
};
export default Feedback;
