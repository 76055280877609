import { PageContent } from '../../page-content';
import { ErrorMessage } from '../error-message';
import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { arrDoubleLeftOutlined } from '@lon/shared/icons';
import { types } from './duck';

const imagesMap: Record<
  Exclude<
    ApplicationEnum,
    ApplicationEnum.LOGIN | ApplicationEnum.MATHNATION_SUIT
  >,
  string
> = {
  [ApplicationEnum.TEACHER_SUIT]: 'suit/assets/images/error-403.png',
  [ApplicationEnum.PARENT_SUIT]: 'suit/assets/images/error-403.png',
  [ApplicationEnum.STUDENT_SUIT]: 'suit/assets/images/error-403-student.png',
  [ApplicationEnum.CAMPUS_LEADER_SUIT]: 'suit/assets/images/error-403.png',
  [ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT]:
    'suit/assets/images/error-403.png',
  [ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT]:
    'suit/assets/images/error-403.png',
  [ApplicationEnum.ADMIN]: 'admin/assets/errors/error-403-admin.png',
  [ApplicationEnum.PLATFORM_ADMIN]:
    'platform-admin/assets/errors/error-403-admin.png',
};

const AccessDeniedContent: React.FC<
  Pick<types.AccessDeniedProps, 'actions' | 'title' | 'description'>
> = ({ actions, title, description }) => {
  const { application } = useContext(WorkingLocation);
  const currentApplication = application as Exclude<
    ApplicationEnum,
    ApplicationEnum.MATHNATION_SUIT | ApplicationEnum.LOGIN
  >;
  const { t } = useTranslation();

  useEffect(() => {
    if (window.parent) {
      window.parent.postMessage({ type: 'page-not-found' });
    }
  }, []);

  return (
    <ErrorMessage
      wrapperProps={{
        px: { base: 6, md: 0 },
        py: { base: 10, md: 0 },
      }}
      bodyWrapperProps={{
        gap: 6,
        padding: 0,
      }}
      title={
        <Flex direction="column" gap={4} maxW="450px">
          <Text variant="h5" as="h1">
            {title || <Trans i18nKey="errorMessage.accessForbidden" />}
          </Text>
          <Text variant="n3" as="h2">
            {description || (
              <Trans
                i18nKey={
                  t('errorMessage.403Body') ||
                  'Contact your System Administrators <br>if you have questions.'
                }
              />
            )}
          </Text>
        </Flex>
      }
      imageSrc={imagesMap[currentApplication!] || ''}
      actions={
        actions || (
          <Button
            as={Link}
            to="/"
            title={t('errorMessage.goToHome')}
            variant="solid"
            leftIcon={<Icon as={arrDoubleLeftOutlined} />}
          >
            <Text as="span" isTruncated>
              {t('errorMessage.goToHome')}
            </Text>
          </Button>
        )
      }
    />
  );
};

const AccessDenied: React.FC<types.AccessDeniedProps> = ({
  title,
  description,
  actions,
  headerTitle,
  withoutSidebar = false,
  withoutWrapper = false,
}) => {
  const { t } = useTranslation();
  const backgroundColor = useThemeStyleValue('blue.75', 'secondary.1000');

  if (withoutWrapper) {
    return <AccessDeniedContent actions={actions} />;
  }

  return (
    <PageContent
      withoutSidebar={withoutSidebar}
      pageTitle={headerTitle || t('errorMessage.accessForbidden')}
      pageContentProps={{
        flexGrow: 1,
        backgroundColor,
      }}
    >
      <Flex flexGrow={1} w="full">
        <AccessDeniedContent
          actions={actions}
          title={title}
          description={description}
        />
      </Flex>
    </PageContent>
  );
};

export default AccessDenied;
