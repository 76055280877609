import { useQuery } from '@apollo/client';
import { Flex, Text } from '@chakra-ui/react';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { WorkingLocation } from '@lon/shared/contexts';
import { StudentAssignmentStatusTransformed } from '@lon/shared/enums';
import { useAuth, useQueryParams } from "@lon/shared/hooks";
import {
  DistrictStudentAssignmentType,
  QueryAssignmentArgs,
  QueryDistrictAssignmentsArgs,
  StudentAssignment,
  useGetUsersQuery, UserPurposeEnum
} from "@lon/shared/requests";
import { getStudentAssignmentStatus } from '@lon/shared/utils';
import {
  AssignmentResponse,
  DistrictAssignmentsResponse,
  GET_ASSIGNMENT,
  GET_DISTRICT_ASSIGNMENT,
  GradingByQuestionsProps,
  OriginalActivityItem,
  useCheckCurrentPage,
} from './duck';
import { Question, ShortcutButtons, StudentAnswers } from './components';
import styles from './GradingByQuestions.module.css';

const GradingByQuestions: React.FC<GradingByQuestionsProps> = ({ isDa }) => {
  const { t } = useTranslation();
  const { currentSchoolId } = useContext(WorkingLocation);
  const { assignmentId } = useParams();
  const [params] = useQueryParams();
  const currentQuestionNumber = Number(params.questionNumber) || 1;
  const [originalActivityItems, setOriginalActivityItems] = useState<
    OriginalActivityItem[]
  >([]);
  const [{ user }] = useAuth();
  const [isLearnosityLoading, setIsLearnosityLoading] = useState(false);

  useCheckCurrentPage(originalActivityItems?.length);

  const { data: stemscopesAssignmentData } = useQuery<
    AssignmentResponse,
    QueryAssignmentArgs
  >(GET_ASSIGNMENT, {
    variables: {
      id: assignmentId,
      schoolId: currentSchoolId,
    },
    fetchPolicy: 'cache-and-network',
    skip: isDa || !assignmentId || !currentSchoolId,
  });

  const { data: districtAssignmentsData } = useQuery<
    DistrictAssignmentsResponse,
    QueryDistrictAssignmentsArgs
  >(GET_DISTRICT_ASSIGNMENT, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        teacherFilter: {
          schoolId: currentSchoolId as string,
          daId: assignmentId,
        },
      },
    },
    skip: !isDa || !assignmentId || !currentSchoolId,
  });

  const { assignment, studentAssignments } = useMemo(() => {
    const districtClassAssignmentsList =
      districtAssignmentsData?.districtAssignments?.collection || [];
    const assignment = isDa
      ? districtClassAssignmentsList.find(
          (districtAssessment) => districtAssessment.studentAssignments?.length
        ) || districtClassAssignmentsList[0]
      : stemscopesAssignmentData?.assignment;

    const districtStudentAssignments = districtClassAssignmentsList
      .flatMap((districtAssessment) => districtAssessment?.studentAssignments)
      .filter(Boolean);
    const stemscopesStudentAssignments =
      stemscopesAssignmentData?.assignment?.studentAssignments?.filter(
        Boolean
      ) || [];
    const studentAssignments = (
      isDa
        ? (districtStudentAssignments as DistrictStudentAssignmentType[])
        : (stemscopesStudentAssignments as StudentAssignment[])
    ).filter((sa) => {
      const status = getStudentAssignmentStatus(
        sa?.status || undefined,
        (sa as StudentAssignment)?.returnedAt
      );

      if (status === null) {
        return false;
      }

      return ![
        StudentAssignmentStatusTransformed.Assigned,
        StudentAssignmentStatusTransformed.Started,
      ].includes(status);
    });

    return {
      assignment,
      studentAssignments,
    };
  }, [stemscopesAssignmentData, districtAssignmentsData]);

  const { data: studentsData } = useGetUsersQuery({
    variables: {
      id_list: studentAssignments
        ?.map((sa) => sa?.studentId)
        .filter(Boolean) as string[],
      purpose: user?.purpose || UserPurposeEnum.Regular
    },
    skip: !studentAssignments?.length,
  });

  const students = studentsData?.users?.collection;

  return (
    <Flex
      direction="column"
      alignItems="center"
      w="full"
      color="primary.800"
      flexGrow="1"
      className={styles.wrapper}
    >
      <ShortcutButtons
        originalActivityItems={originalActivityItems}
        isLearnosityLoading={isLearnosityLoading}
      />
      {!!originalActivityItems?.length && (
        <Text variant="h3" my="5">
          {t('studentAssignmentDetails.currentQuestion', {
            questionNumber: originalActivityItems[currentQuestionNumber - 1]
              ? currentQuestionNumber
              : null,
          })}
        </Text>
      )}
      <Question
        originLearnosityId={assignment?.originLearnosityId}
        originalActivityItems={originalActivityItems}
        setOriginalActivityItems={setOriginalActivityItems}
      />
      <StudentAnswers
        isDa={isDa}
        isLearnosityLoading={isLearnosityLoading}
        setIsLearnosityLoading={setIsLearnosityLoading}
        studentAssignments={studentAssignments}
        originalActivityItems={originalActivityItems}
        students={students}
      />
    </Flex>
  );
};

export default GradingByQuestions;
