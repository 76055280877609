export const skipTranslationForLernosityElements = () => {
  if (
    typeof (window as any).runGoogleTranslate === 'function' &&
    window.document.documentElement.lang !== 'en'
  ) {
    document
      .querySelectorAll('.lrn_editor_area > p')
      ?.forEach((label) => label.classList.add('notranslate'));
    document
      .querySelectorAll('.lrn_choiceLabel')
      ?.forEach((label) => label.classList.add('notranslate'));
    (window as any).runGoogleTranslate(
      window.document.documentElement.lang || 'en'
    );
  }
};
