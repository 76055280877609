import { Box, Flex, Tag, Text } from '@chakra-ui/react';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { sxDarkScrollBar } from '@lon/shared/constants';
import { ScopeContext } from '@lon/suit/contexts';
import { Section, countNestedItems } from './duck';
import { Activities, Breadcrumbs, Items } from './components';

const FactFluencyElementList: React.FC = () => {
  const { t } = useTranslation();
  const { sectionId } = useParams() as {
    sectionId: string;
  };
  const { sections } = useContext(ScopeContext);
  const currentTopic =
    (sections?.find(
      (section) => section.identifier === sectionId
    ) as Section) || null;

  const { elementsCount } = useMemo(
    () => countNestedItems(currentTopic),
    [currentTopic]
  );

  return (
    <Box role="tabpanel" id="scope-elements" aria-labelledby="all-elements">
      <Breadcrumbs />
      <Flex
        flexDirection="column"
        backgroundColor="white"
        borderRadius="6px 6px"
        width="full"
        overflow="hidden"
      >
        <Flex width="full" borderRadius="6px 6px 0px 0px" css={sxDarkScrollBar}>
          <Flex width="full" height="100%" alignItems="center" px={8} h="64px">
            <Text variant="h5" color="blue.500">
              {t('factFluency.activities')}
            </Text>
            <Tag
              variant="counter"
              border="none"
              ml="12px"
              color="primary.700"
              p="0px 12px"
            >
              {elementsCount}
            </Tag>
          </Flex>
        </Flex>

        <Flex
          height="100%"
          flexDirection="column"
          gap={2}
          css={sxDarkScrollBar}
        >
          <Flex
            height="100%"
            flexDirection="column"
            width="full"
            backgroundColor="white"
          >
            <Box w="full">
              {!!currentTopic?.elements?.length && (
                <Items
                  level={1}
                  items={currentTopic?.elements}
                  sectionId={currentTopic?.identifier}
                />
              )}
              {!!currentTopic?.sections?.length && (
                <Activities
                  items={currentTopic?.sections}
                  level={1}
                  sectionId={currentTopic?.identifier}
                />
              )}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default FactFluencyElementList;
